import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import MultiChipInput from "../../../common/inputElements/MultiChipInput";
import TextInput from "../../../common/inputElements/TextInput";
import throttle from "../../../utils/throttle";
import { showToast } from "../../../redux/actions/toastActions";

const AddStall = ({ open, setOpen, isEdit, setIsEdit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const eventsId = useMatch("/events/:eventId/*");
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ mode: "onChange" });

  const onSubmit = async (data) => {
    if (chips?.length === 0 && inputValue?.trim() === "") {
      dispatch(
        showToast({
          message: "Please add atleast one stall name",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    console.log(data, chips);

    setIsSubmitting(true);
  };

  useEffect(() => {
    if (open && !isEdit) {
      reset({
        stallType: "Shell",
      });
    } else {
    }
  }, [open, isEdit, reset]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          {isEdit ? "Edit Stall" : "Add Stall"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[50px] flex-1 px-4 sm:px-6 mb-[60px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <p className="mb-1 text-neutral-900">Stall Name</p>
                          <MultiChipInput
                            setInputValue={setInputValue}
                            inputValue={inputValue}
                            setChips={setChips}
                            chips={chips}
                            limit={4}
                            label="Stall Name"
                            required={true}
                            style={{ marginBottom: "20px" }}
                            note="You can add multiple stalls by pressing the 'Enter' key after entering the name of each stall."
                          />

                          <p className="mb-1.5 text-neutral-900">Stall Type</p>

                          <div>
                            {["Shell", "Bare"].map((type, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`border-2 rounded-[10px] py-[16px] px-[16px] w-full h-[120px] flex items-center justify-between mb-5 cursor-pointer hover:border-2 hover:border-green-500 border-${
                                    watch().stallType === type
                                      ? "green-500"
                                      : "[#EDEDED]"
                                  }`}
                                  onClick={() => {
                                    if (watch().stallType !== type) {
                                      reset({ stallSize: "" });
                                    }
                                    setValue("stallType", type);
                                  }}
                                >
                                  <div>
                                    <p className="font-semibold">{type}</p>

                                    <p className="text-sm text-opacity-[0.5] text-black font-normal my-3">
                                      6 tables | 8 chairs | 3 power sockets | 4
                                      spotlights
                                    </p>

                                    <div className="flex gap-1.5 items-center">
                                      <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.34323 14.7278L3.5148 17.5563L7.05033 21.0918L20.4854 7.65678L16.9498 4.12124L14.8285 6.24257L16.2427 7.65678L14.8285 9.07099L13.4143 7.65678L11.293 9.7781L13.4143 11.8994L12.0001 13.3136L9.87876 11.1923L7.75744 13.3136L9.17165 14.7278L7.75744 16.1421L6.34323 14.7278ZM17.6569 1.99992L22.6067 6.94967C22.9972 7.3402 22.9972 7.97336 22.6067 8.36389L7.75744 23.2131C7.36692 23.6037 6.73375 23.6037 6.34323 23.2131L1.39348 18.2634C1.00295 17.8729 1.00295 17.2397 1.39348 16.8492L16.2427 1.99992C16.6332 1.6094 17.2664 1.6094 17.6569 1.99992Z"
                                          fill="black"
                                          fill-opacity="0.5"
                                        />
                                      </svg>
                                      <span className="text-black text-opacity-[0.5] text-[15px] font-normal">
                                        10 sizes
                                      </span>
                                    </div>
                                  </div>

                                  <div>
                                    <svg
                                      width="25"
                                      height="25"
                                      viewBox="0 0 48 48"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clipPath="url(#clip0_118_2684)">
                                        <path
                                          d="M24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 12.96 35.04 4 24 4ZM20 34L10 24L12.82 21.18L20 28.34L35.18 13.16L38 16L20 34Z"
                                          fill={
                                            watch().stallType === type
                                              ? "#2ECC71"
                                              : "#C5C5C7"
                                          }
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_118_2684">
                                          <rect
                                            width="48"
                                            height="48"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {watch().stallType === "Shell" ? (
                            <>
                              <p className="mb-1.5 text-[#121212] mt-6">
                                Select Size
                              </p>

                              <div className="flex items-center gap-4">
                                {[
                                  "9 sqm.",
                                  "12 sqm.",
                                  "15 sqm.",
                                  "16 sqm.",
                                  "20 sqm.",
                                ].map((size, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={`w-[65px] h-[35px] rounded-[15px] grid text-[14px] cursor-pointer place-content-center font-semibold ${
                                        watch().stallSize === size
                                          ? "bg-primary text-white"
                                          : "bg-[#F4F6F9] text-black"
                                      }`}
                                      onClick={() => {
                                        setValue("stallSize", size);
                                      }}
                                    >
                                      {size}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            <div>
                              <p className="mb-7 text-[#121212] mt-5">
                                Select Size (W x H x L)
                              </p>

                              <TextInput
                                register={register}
                                type="text"
                                id={"stallSize"}
                                required
                                label="Stall Size"
                                mb={5}
                              />
                            </div>
                          )}

                          <p className="mb-1.5 text-[#121212] mt-7">
                            Stall Category
                          </p>

                          <div className="flex item-center gap-3">
                            {["Standard", "Premium"].map((category, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`w-[93px] h-[33px] flex justify-center items-center rounded-[15px] cursor-pointer text-[14px] font-semibold ${
                                    watch().stallCategory === category
                                      ? "bg-primary text-white"
                                      : "bg-[#F4F6F9] text-black"
                                  }`}
                                  onClick={() => {
                                    setValue("stallCategory", category);
                                  }}
                                >
                                  {category}
                                </div>
                              );
                            })}
                          </div>

                          <p className="mb-1.5 text-neutral-900 mt-7">
                            Number of open sides
                          </p>

                          <div className="flex items-center gap-4 mb-10">
                            {[1, 2, 3, 4].map((side) => {
                              return (
                                <div
                                  key={side}
                                  className={`${
                                    watch().openSides === side
                                      ? "bg-primary text-white"
                                      : "bg-[#F4F6F9] text-black"
                                  } w-[30px] h-[30px] rounded-[15px] grid place-items-center cursor-pointer text-[14px] font-semibold`}
                                  onClick={() => setValue("openSides", side)}
                                >
                                  {side}
                                </div>
                              );
                            })}
                          </div>

                          <TextInput
                            register={register}
                            type="text"
                            id={"badges"}
                            required
                            label="Number of badges"
                            mb={9}
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"hallName"}
                            required
                            label="Hall Name"
                            mb={5}
                          />

                          <div className="text-[24px] border-t py-5 text-[#121212] font-normal mb-3">
                            Add ons
                          </div>

                          <TextInput
                            register={register}
                            type="text"
                            id={"tables"}
                            required
                            label="Add tables"
                            mb={9}
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"chairs"}
                            required
                            label="Add Chairs"
                            mb={9}
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"powerSockets"}
                            required
                            label="Add power sockets"
                            mb={9}
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"spotLights"}
                            required
                            label="Add Spotlights"
                            mb={9}
                          />

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={isSubmitting ? "Loading..." : "Add Stall"}
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AddStall;
