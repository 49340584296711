import React from "react";

const StallTable = ({ isEdit, setIsEdit, setOpen }) => {
  return (
    <div className="flex-grow md:w-full rounded-lg border mt-5">
      <table className=" table-auto mb-[30px] ">
        <thead className=" border-slate-200 bg-[#F4F6F9] rounded-lg">
          <tr className="">
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left  leading-[45px] w-[110px]">
              Stall number
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[90px]">
              Hall name
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[80px]">
              Stall type
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[120px] ">
              Stall category
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[140px] ">
              Stall size (W x H x L)
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[40px] ">
              sqft
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[40px]">
              Badges
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[120px] ">
              Assigned to
            </th>
            <th className="text-[12px] px-2 first:pl-5 last:pr-5 py-[8px] font-[600]  text-left leading-[45px] w-[100px] ">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="md:w-[100%] ">
          <td className="text-[12px]  px-2 first:pl-5 last:pr-5 py-3  font-[500]">
            S1
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            Hall 1
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            Shell
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            Standard
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            10W * 10H * 10L{" "}
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            sqft
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            7
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            Ripple Design
          </td>
          <td className="text-[12px]  px-2 first:pl-3 last:pr-5 py-3  font-[500]">
            <div className="flex items-center gap-2">
              <img
                src="/svgs/Edit.svg"
                alt="edit"
                title="Edit"
                className="w-6 h-6 cursor-pointer"
                onClick={(e) => {
                  setIsEdit(true);
                  setOpen(true);
                }}
              />
              <img
                src="/svgs/Delete.svg"
                alt="delete"
                title="Delete"
                className="w-6 h-6 cursor-pointer"
                onClick={(e) => {}}
              />
            </div>
          </td>
        </tbody>
      </table>
    </div>
  );
};

export default StallTable;
