import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import Home from "../../svg/Home";
import Speakers from "../../svg/Speakers";
import Sponsors from "../../svg/Sponsors";
import Form from "../../svg/Form";
import Schedule from "../../svg/Schedule";
import Communication from "../../svg/Communication";
import CheckIn from "../../svg/CheckIn";
import { getSingleEvent } from "../../redux/actions/eventActions";
import IDCard from "../../svg/IDCard";
import SettingsSvg from "../../svg/SettingsSvg";
import { showToast } from "../../redux/actions/toastActions";
import Stalls from "../../svg/Stalls";
function SideMenu({ eventTitle, organizer }) {
  const singleEvent = useSelector((state) => state.eventData);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const eventsId = useMatch("events/:eventId/*");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  const navbarContent = [
    {
      name: "Event Info",
      imageUrl: (
        <Home
          color={searchParams.get("show") === "eventInfo" ? "#A55EEA" : ""}
        />
      ),
      pathName: "eventInfo",
      comingSoon: false,
    },
    {
      name: "Speakers",
      imageUrl: (
        <Speakers
          color={searchParams.get("show") === "speakers" ? "#A55EEA" : ""}
        />
      ),
      pathName: "speakers",
      comingSoon: false,
    },
    {
      name: "Schedule",
      imageUrl: (
        <Schedule
          color={searchParams.get("show") === "schedule" ? "#A55EEA" : ""}
        />
      ),
      pathName: "schedule",
      comingSoon: false,
    },
    {
      name: "Sponsors",
      imageUrl: (
        <Sponsors
          color={searchParams.get("show") === "sponsors" ? "#A55EEA" : ""}
        />
      ),
      pathName: "sponsors",
      comingSoon: false,
    },
    {
      name: "Registrations",
      imageUrl: (
        <IDCard
          color={searchParams.get("show") === "registrations" ? "#A55EEA" : ""}
        />
      ),
      pathName: "registrations",
      comingSoon: false,
    },
    {
      name: "Analytics",
      imageUrl: (
        <Form
          color={searchParams.get("show") === "analytics" ? "#A55EEA" : ""}
        />
      ),
      pathName: "analytics",
      comingSoon: false,
    },
    {
      name: "Communications",
      imageUrl: (
        <Communication
          color={searchParams.get("show") === "communications" ? "#A55EEA" : ""}
        />
      ),
      pathName: "communications",
      comingSoon: true,
    },
    {
      name: "Stalls",
      imageUrl: (
        <Stalls
          color={searchParams.get("show") === "stalls" ? "#A55EEA" : ""}
        />
      ),
      pathName: "stalls",
      comingSoon: true,
    },
    // {
    //   name: "Team",
    //   imageUrl: <People color={"#A55EEA"} />,
    //   pathName: "team",
    //   comingSoon: false,
    // },
    {
      name: "Settings",
      imageUrl: (
        <SettingsSvg
          color={searchParams.get("show") === "settings" ? "#A55EEA" : ""}
        />
      ),
      pathName: "settings",
      comingSoon: true,
    },
  ];
  // ${
  //     singleEvent.isMockEvent ? "h-[calc(100vh_-_98px)]" : " h-[calc(100vh_-_58px)]"
  // }
  return (
    <aside
      className={`hidden md:block w-[228px] fixed h-screen border-r border-[#C5C5C766] border-opacity-40 rounded bg-white aria-label=Sidebar md:h-[90%] overflow-y-scroll overflow-x-hidden scrollbar-hide md:pb-7`}
    >
      <div className="bg-white">
        <div className="">
          <div
            className="text-[18px] font-[600] relative left-[25px] top-[25px] break-normal w-[140px] hover:underline cursor-pointer"
            onClick={() =>
              window.open(
                `${window.location.origin}/event/${eventsId.params.eventId}`,
                "_blank"
              )
            }
          >
            <div className="line-clamp-3">
              {eventTitle
                ? eventTitle.charAt(0).toUpperCase() +
                  eventTitle.slice(1, eventTitle.length)
                : null}
            </div>
          </div>
          <i
            className="fa-solid fa-up-right-from-square absolute right-[43px] mt-1 cursor-pointer text-primary ml-2"
            onClick={() =>
              window.open(
                `${window.location.origin}/event/${eventsId.params.eventId}`,
                "_blank"
              )
            }
          ></i>

          <i
            className="fa-regular fa-copy absolute right-[13px] mt-1 cursor-pointer text-primary "
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/event/${singleEvent._id}`
              );
              dispatch(
                showToast({
                  message: "Link Copied to Clipboard",
                  category: "success",
                })
              );
            }}
          ></i>
        </div>

        <p className="text-[10px] font-[500] relative left-[27px] top-[25px] text-gray-400">
          By {organizer ? organizer : null}
        </p>

        <div
          id="dropdown"
          className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            <li>
              <p className="font-[600] text-[13px] block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                MakerMea
              </p>
            </li>
            <li>
              <p className="font-[600] text-[13px] block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                Riidl
              </p>
            </li>
            <li>
              <p className="font-[600] text-[13px] block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                Dubai Expo
              </p>
            </li>
          </ul>
        </div>

        {/* <p className="">{}</p> */}
        <ul className="pt-10">
          {navbarContent &&
            navbarContent.length > 0 &&
            navbarContent.map((navbar, index) => (
              <li key={index} className="relative">
                <div
                  onClick={() => {
                    navigate(
                      `/events/${eventsId.params.eventId}?show=${navbar.pathName}`
                    );
                  }}
                  className={`flex cursor-pointer text-[#9a9a9a] items-center pl-5 py-3.5 text-[11px] font-[500] border-b border-[#C5C5C766] border-opacity-40 ${
                    searchParams.get("show") === navbar.pathName
                      ? "text-primary"
                      : ""
                  }`}
                >
                  <>{navbar?.imageUrl}</>
                  <span
                    className={`pl-[7px] pt-[2px] font-[600] ${
                      searchParams.get("show") === navbar.pathName
                        ? "text-primary"
                        : ""
                    }`}
                  >
                    {navbar.name}
                  </span>
                  {navbar.comingSoon ? (
                    <span className="absolute bottom-[4px] right-[0px] bg-[#83f5b2]  text-green-800 text-[9px] font-medium mr-2 px-2.5 py-0.5 rounded">
                      New
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          <li key={"checkIn"}>
            <div
              onClick={() => {
                window.open(
                  `${window.location.origin}/events/${eventsId.params.eventId}/registrations?location=eventCheckin&tab=Attendees&view=all`,
                  "_blank"
                );
              }}
              className={`flex cursor-pointer text-[#9a9a9a] items-center pl-5 py-3.5 text-[11px] font-[500] border-b border-[#C5C5C766] border-opacity-40 ${
                // searchParams.get("show") === navbar.pathName ? "text-primary" : ""
                ""
              }`}
            >
              {/* <img
                  src={navbar.imageUrl}
                  alt={navbar.name}
                  className="h-6 w-6 text-primary"
                /> */}
              <CheckIn />
              <span
                className={`pl-[7px]  font-[600] ${
                  //   searchParams.get("show") === navbar.pathName ? "text-primary" : ""
                  ""
                }`}
              >
                Check In
              </span>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default SideMenu;
