import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMatch, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";
import TextInput from "../../../../common/inputElements/TextArea";
import AddTickets from "./AddTickets";
import AddCoupons from "./AddCoupons";
import Toggle from "../../../../common/inputElements/Toggle";
import { async } from "q";
import { showToast } from "../../../../redux/actions/toastActions";
import { getSingleEvent } from "../../../../redux/actions/eventActions";

const EventTickets = () => {
  const eventsId = useMatch("events/:eventId/*");
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [isEditCoup, setIsEditCoup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [singleTicket, setSingleTicket] = useState({});
  const [allTickets, setAllTickets] = useState([]);
  const [ticketCategoryId, setTicketCategoryId] = useState("");
  const navigate = useNavigate();
  const [couponData, setCouponData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const singleEvent = useSelector((state) => state.eventData);
  const [isPaid, setIsPaid] = useState(singleEvent.isPaid);
  const [phonepe, setPhonepe] = useState(false);
  const [razorpay, setRazorpay] = useState(false);
  const [stripe, setStripe] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    alert("please login!! access token not found");
    navigate("/login");
  }
  useEffect(() => {
    if (!open) {
      setIsEdit(false);
      setSingleTicket({});
    }
  }, [open]);

  useEffect(() => {
    if (!open3) {
      setIsEditCoup(false);
      setCouponData({});
      setTicketCategoryId("");
    }
  }, [open3]);

  async function getAllTickets() {
    const tickets = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/event/${singleEvent._id}/ticketCategories`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": `${accessToken}`,
        },
      }
    );

    const data = await tickets.json();
    if (!data) {
      alert("something went wrong!");
    } else {
      setAllTickets(data);
    }
  }

  async function updatePaid(eventId) {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/event/${eventId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            isPaid: isPaid,
            paymentGateway: {
              phonepe: phonepe,
              razorpay: razorpay,
              stripe: stripe,
            },
          }),
        }
      );

      const data = await response.json();

      dispatch(getSingleEvent({ eventId: eventId }));
      dispatch(
        showToast({
          message: "Event updated Successfully",
          category: "success",
        })
      );
    } catch (err) {
      console.log(err);
    }
  }
  // function ModalChild({ticketId}){
  //   return (
  //     <div className="p-8 flex flex-col gap-8">
  //       <p className="text-center"> Do you want to delete?</p>
  //       <div className="flex gap-4">

  //       <PrimaryButton onClick={()=>deleteTicket(ticketId)} btnText={"Delete"}></PrimaryButton>
  //       <PrimaryButton onClick={()=> setOpen5(false)} btnText={"Cancel"}></PrimaryButton>
  //       </div>
  //     </div>
  //   )
  // }
  async function deleteTicket(ticketId) {
    const tickets = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/ticketCategories/${ticketId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          // "x-access-token": accessToken`,
        },
      }
    );

    const message = await tickets.json();
    if (!message) {
      alert("something went wrong!");
    } else {
      getAllTickets();
      dispatch(
        showToast({
          message: "Ticket Deleted Succesfully",
          category: "success",
        })
      );
    }
  }

  useEffect(() => {
    getAllTickets();

    setIsPaid(singleEvent.isPaid);
    if (singleEvent.paymentGateway) {
      setPhonepe(singleEvent.paymentGateway?.phonepe);
      setRazorpay(singleEvent.paymentGateway?.razorpay);
      setStripe(singleEvent.paymentGateway?.stripe);
    }
  }, [singleEvent._id]);

  useEffect(() => {
    if (
      singleEvent &&
      (isPaid !== singleEvent.isPaid ||
        (singleEvent.paymentGateway &&
          (phonepe !== singleEvent.paymentGateway.phonepe ||
            razorpay !== singleEvent.paymentGateway.razorpay ||
            stripe !== singleEvent.paymentGateway.stripe)))
    ) {
      updatePaid(singleEvent._id);
      // console.log(isPaid);
    }
  }, [isPaid, phonepe, razorpay, stripe]);

  //     if (isPaid !== singleEvent.isPaid) {
  //       updatePaid(singleEvent._id);
  //       // console.log(isPaid);
  //     }
  //   }, [isPaid]);
  console.log(allTickets);

  return (
    <div className="w-full md:w-[400px] h-screen ml-0 md:ml-[0px] mt-5 md:mt-[30px] ">
      <div className="flex items-center justify-between">
        <p className="font-[600] text-[20px] text-[#585858]">Ticketing</p>
        <div className="flex justify-between ">
          <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
            Enable Ticketing{" "}
          </p>
          <Toggle isChecked={isPaid} setIsChecked={setIsPaid} id="isPaid" />
        </div>
      </div>

      {/* <div className="flex items-center flex-col justify-between">
        <p className="font-[600] mx-auto md:mx-auto text-[20px] text-[#585858] md:ml-8">
          Payment
        </p>
        
        <div className="flex gap-12" >
          <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
            Phonepe
          </p>
          <Toggle isChecked={phonepe} setIsChecked={setPhonepe} id={`phonepe`} />
        </div>
        <div className="flex gap-12" >
          <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
            Razorpay
          </p>
          <Toggle isChecked={razorpay} setIsChecked={setRazorpay} id={`razorpay`} />
        </div>
        <div className="flex gap-12" >
          <p className="text-[13px] text-[#585858] relative top-[6px] mr-[10px]">
            Stripe
          </p>
          <Toggle isChecked={stripe} setIsChecked={setStripe} id={`stripe`} />
        </div>
      </div> */}

      <div className="w-[422px] mt-[30px]">
        {allTickets?.length > 0 ? (
          allTickets.map((ele, index) => {
            return (
              <div key={ele._id}>
                <div className="my-4">
                  <div className="grid grid-cols-[0.5fr_160px_60px]">
                    <div className="flex items-center">
                      <i className="fa-solid fa-ticket text-gray-400"></i>
                      <div className="pl-2.5 w-[197px]">
                        <div className="text-[14px] font-semibold py-1 capitalize">
                          {ele.name}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center w-[180px]">
                      <p className="text-[13px]">Price : </p>
                      <div className="pl-2.5">
                        <div className="text-[14px] font-semibold py-1 capitalize">
                          <span className="text-[15px] font-[500] text-gray-600 line-through">
                            {" "}
                            ₹{ele.price}
                          </span>
                          <span className="text-[15px] font-[500] inline-block ml-2">
                            {" "}
                            ₹{ele.discountValue}
                          </span>
                        </div>
                      </div>
                    </div>
                    {deleteId === ele._id ? (
                      <div className="flex">
                        <img
                          src="/svgs/done.svg"
                          alt="done"
                          className="w-6 h-6 cursor-pointer mr-3"
                          onClick={async () => {
                            deleteTicket(deleteId);
                          }}
                        />
                        <img
                          src="/svgs/Cross.svg"
                          alt="delete"
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setDeleteId("")}
                        />
                      </div>
                    ) : (
                      <div className="flex">
                        <img
                          src="/svgs/Edit.svg"
                          alt="edit"
                          className="w-6 h-6 cursor-pointer -ml-3 mr-2"
                          onClick={() => {
                            setSingleTicket(ele);
                            setOpen(true);
                            setIsEdit(true);
                          }}
                        />
                        <img
                          src="/svgs/Delete.svg"
                          alt="delete"
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setDeleteId(ele._id)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="my-2">
                    <p className="text-[12px] font-[500] text-gray-600 capitalize">
                      {" "}
                      {ele.description}
                    </p>
                  </div>
                  <div className="w-full grid grid-cols-[1fr_80px]">
                    <div>
                      {ele.coupons.map((el, index) => {
                        return (
                          <p
                            key={el._id}
                            className="text-[11px] text-gray-600 border border-primary inline-block py-[1px] px-[7px] mr-[5px] rounded-[2px] cursor-pointer"
                            onClick={() => {
                              setTicketCategoryId(ele._id);
                              setCouponData({
                                _id: el._id,
                                couponType: el.couponType,
                                validUntil: new Date(el.validUntil),
                                couponCode: el.couponCode,
                                maxRedeemCount: el.maxRedeemCount,
                                discountType: el.discountType,
                                discountValue: el.discountValue,
                                // moment
                                //         .utc(session.startTime)
                                //         .local()
                                //         .format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                              });
                              setIsEditCoup(true);
                              setOpen3(true);
                            }}
                          >
                            {el.couponCode}
                          </p>
                        );
                      })}
                    </div>
                    <div
                      className="bg-gray-300 text-black h-[23px] py-1 px-[5px] text-[9px] ml-0 my-2 font-[600] rounded-[5px] w-[80px] flex items-center text-center justify-center cursor-pointer"
                      onClick={() => {
                        setOpen3(true);
                        setIsEditCoup(false);
                        setTicketCategoryId(ele._id);
                      }}
                    >
                      Add Coupon
                    </div>
                  </div>
                </div>
                <div className="w-[325px] md:w-[422px]">
                  <hr />
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[200px] h-[200px]"
            />
            <p className="text-[15px] font-[500] text-[#717171]">
              No tickets found...
            </p>
          </div>
        )}

        <div className="mt-[50px]"></div>
        <div className="w-[335px] md:w-[250px]">
          <PrimaryButton
            btnText={"Add Tickets"}
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
          />
        </div>
      </div>
      <AddTickets
        open={open}
        setOpen={setOpen}
        singleEvent={singleEvent}
        getAllTickets={getAllTickets}
        setIsEdit={setIsEdit}
        isEdit={isEdit}
        singleTicket={singleTicket}
        setSingleTicket={setSingleTicket}
      />
      <AddCoupons
        open={open3}
        setOpen={setOpen3}
        singleEvent={singleEvent}
        getAllTickets={getAllTickets}
        setIsEdit={setIsEditCoup}
        isEdit={isEditCoup}
        ticketCategoryId={ticketCategoryId}
        singleCoupon={couponData}
      />

      {/* <Modal 
        isOpen={open5}
        setIsOpen={setOpen5}
        children={<ModalChild  ticketId={deleteId}/>}
      /> */}
    </div>
  );
};

export default EventTickets;
