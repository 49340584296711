import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import socketIOClient from "socket.io-client";
import Confetti from "react-confetti";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";
import { getRequest } from "../../../utils/API/api.ts";
const url = new URL(process.env.REACT_APP_SERVER_URL);
const baseUrl = `${url.protocol}//${url.host}`;

const EventBillboard = () => {
  const eventsId = useMatch("/events/:eventId/*");
  const [user, setUser] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    const getAllEventAttendees = async () => {
      const response = await getRequest(`/attendee/${eventsId.params.eventId}`);
      fetchLatestUser(response.data.attendees);
      setRegistrations(response.data.attendees);
    };
    getAllEventAttendees();
  }, []);

  useEffect(() => {
    let animationTimeout;

    if (showAnimation) {
      animationTimeout = setTimeout(() => {
        setShowAnimation(false);
      }, 5000);
    }

    return () => {
      clearTimeout(animationTimeout);
    };
  }, [showAnimation]);

  useEffect(() => {
    let eventId = eventsId?.params?.eventId;
    const socket = socketIOClient(baseUrl);
    socket.emit("joinEvent", eventId);

    socket.on("updateCheckinList", (user) => {
      setShowAnimation(Date.now());
      setUser(user);
    });

    return () => {
      socket.disconnect();
    };
  }, [eventsId.params.eventId, setShowAnimation]);

  function getEventSpecificData(attendee) {
    return Array.isArray(attendee.attendee)
      ? attendee.attendee[0]?.eventSpecificData
      : attendee.attendee?.eventSpecificData;
  }

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find(
      (data) => data.eventId === eventsId.params.eventId
    );
  }

  const getLatestCheckinTime = (attendee) => {
    const eventSpecificData = getEventSpecificData(attendee);
    const eventData = getEventData(eventSpecificData);

    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn : null;
    }
  };

  const fetchLatestUser = (registrations) => {
    const latestCheckinUser = registrations?.reduce((latestUser, user) => {
      const userCheckinTime = getLatestCheckinTime(user);
      if (!latestUser) return user;
      if (!userCheckinTime) return latestUser;

      const latestCheckin = getLatestCheckinTime(latestUser);
      if (!latestCheckin) return user;

      return new Date(userCheckinTime.entry) > new Date(latestCheckin.entry)
        ? user
        : latestUser;
    }, null);
    setShowAnimation(true);
    setUser(latestCheckinUser);
  };
  return (
    <div className="bg-primary flex justify-center items-center h-screen relative w-screen">
      <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center text-[50px]">
        {showAnimation && (
          <>
            <Confetti
              width={window.innerWidth}
              height={window.innerHeight}
              friction={1.0}
              numberOfPieces={600}
              gravity={0.2}
            />
          </>
        )}
      </div>

      <div className="mymd:rounded-[10px] mymd:w-[70%] mymd:h-[85%] w-full h-full justify-center items-center bg-white mymd:shadow-[0_0px_10px_2px_rgba(0,0,0,0.1)] text-[70px] flex flex-col p-3">
        {user?.firstName ? (
          <>
            {user?.profilePicture ? (
              <img
                src={user?.profilePicture}
                className="w-[150px] h-[150px] rounded-[50%] object-cover"
                alt="profile"
              />
            ) : (
              <div className=" ">
                <DefaultProfilePicture
                  firstName={user?.firstName}
                  lastName={user?.lastName}
                  style={{
                    width: "150px",
                    height: "150px",
                    borderRadius: "300px",
                    fontSize: "60px",
                  }}
                />
              </div>
            )}

            <div className="mymd:text-[45px] text-[30px] mt-8 text-center font-[500]">
              <span className="font-[600] text-primary">
                {user?.firstName + " " + user?.lastName + " "}
              </span>
              checked in!
            </div>
          </>
        ) : (
          <div className="mymd:text-[45px] text-[30px] mt-8 text-center font-[500]">
            No attendees Yet
          </div>
        )}
      </div>
    </div>
  );
};

export default EventBillboard;
