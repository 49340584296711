import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { useForm } from "react-hook-form";

import TextInput from "../../../../../common/inputElements/TextInput";
import moment from "moment";
import MultiChipInput from "../../../../../common/inputElements/MultiChipInput";

export default function OpenEmailNotifications({
  open,
  setOpen,
  event,
  handleToggle,
  toggle,
  inputValue,
  setInputValue,
  chips,
  setChips,
  isSubmitting,
}) {
  const { register, reset } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    reset({
      emailSubject: `Warpbay EOD Report - ${event.title}`,
    });
  }, [event]);

  const today = moment();
  const yesterday = moment().subtract(1, "day");
  const formattedCurrentDate = today.format("dddd, D MMM YYYY");
  const formattedYesterdayDate = yesterday.format("dddd, D MMM YYYY");

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          Email Notifications
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[48px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <MultiChipInput
                          setInputValue={setInputValue}
                          inputValue={inputValue}
                          setChips={setChips}
                          chips={chips}
                          limit={4}
                          label="To"
                          required={true}
                          note="Press 'Enter' key to add more emails"
                        />

                        <TextInput
                          register={register}
                          type="text"
                          id={"emailSubject"}
                          disabled={true}
                          required
                          label="Email Subject"
                          mb={10}
                        />
                        <div className="relative mb-[25px]">
                          <label
                            htmlFor="startDate"
                            className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[12px]"
                          >
                            Email Body <span className="text-red-600">*</span>
                          </label>
                          <div className="rounded-[10px] w-full bg-gray-50 border border-gray-300 text-gray-900 p-2.5 text-[13px] font-[500]">
                            <div className="flex flex-col gap-[10px] leading-6">
                              <p>
                                Dear Team, {event?.primaryOrganizer?.firstName}{" "}
                                {event?.primaryOrganizer?.lastName}
                              </p>
                              <p>Here is the report for {event?.title}</p>
                              <p>
                                Registration count - {`{{totalRegistrations}}`}
                              </p>
                              <p>
                                Registration for Today {formattedCurrentDate} -{" "}
                                {`{{currrentDateRegistrations}}`}
                              </p>
                              <p>
                                Registered yesterday {formattedYesterdayDate} -{" "}
                                {`{{pastDateRegistrations}}`}
                              </p>

                              <p>Sponsor Count - {`{{totalSponsors}}`}</p>
                              <p>Attendees Count - {`{{totalAttendees}}`}</p>
                              <p>
                                Attendee count for Today {formattedCurrentDate}{" "}
                                - {`{{currentDateAttendees}}`}
                              </p>
                              <p>
                                Attendees count for yesterday{" "}
                                {formattedYesterdayDate} -{" "}
                                {`{{pastDateAttendees}}`}
                              </p>
                              <p>
                                Thanks and regards,
                                <br />
                                Team Warpbay.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center ml-[-2px]">
                          {toggle ? (
                            <div className="flex justify-between w-full gap-4">
                              <input
                                value={
                                  isSubmitting
                                    ? "Loading..."
                                    : "Turn Off Notifications"
                                }
                                type="submit"
                                className="h-[42px] w-full bg-[#E74C3C] rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer"
                                onClick={() => handleToggle(false)}
                                disabled={isSubmitting}
                              />
                              <input
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="h-[42px] w-full bg-primary rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer"
                                onClick={() => handleToggle(true)}
                                disabled={isSubmitting}
                              />
                            </div>
                          ) : (
                            <input
                              value={
                                isSubmitting
                                  ? "Loading..."
                                  : "Turn On Notifications"
                              }
                              type="submit"
                              className="h-[42px] w-full bg-primary rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer"
                              onClick={() => handleToggle(true)}
                              disabled={isSubmitting}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
