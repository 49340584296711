import React, { useState, useEffect } from "react";
import styles from "./Landing.module.css";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import LandingSocialBtn from "./LandingSocialBtn";
import moment from "moment";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import GoogleCalendar from "./GoogleCalendar";

var initialThrottleCall = false;
function throttle(fn, delay) {
  try {
    if (initialThrottleCall === false) {
      fn();
      initialThrottleCall = Date.now();
    } else if (Date.now() - initialThrottleCall > delay) {
      fn();
      initialThrottleCall = Date.now();
    }
  } catch (error) {
    console.log(error);
  }
}

const LandingPage = ({ singleEvent }) => {
  const vercelDomain = "https://dev.warpbay.com/";
  const [linkedin, setLinkedin] = useState(false);
  const [googleCal, setGoogleCal] = useState(false);
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [copied, setCopied] = useState("");
  const xmas95 = new Date(singleEvent.startDate);
  const optionmymdonth = { month: "short" };
  const eventsId = useMatch("/event/:eventId");
  const [linkData, setlinkData] = useState({});
  const [shared, setShared] = useState(false);
  const [loading, setLoading] = useState(false);

  const { linkedInLogin } = useLinkedIn({
    clientId: "77q4vi8kkicqo6",
    redirectUri: `${vercelDomain}linkedin`,
    scope: "r_emailaddress,r_basicprofile,w_member_social",
    // scope: "r_emailaddress,w_member_social,r_compliance",
    onSuccess: (code) => {
      if (localStorage.getItem("linkedinAccessToken")) {
        throttle(
          getLinkedinAccessToken(
            code,
            localStorage.getItem("linkedinAccessToken")
          ),
          10000
        );
      } else {
        throttle(getLinkedinAccessToken(code, null), 10000);
      }
    },
    onError: (error) => {
      setLinkedin(false);
      console.log(error);
      setLoading(false);
    },
  });
  async function getLinkedinAccessToken(
    code,
    linkedinAccessTokenClient = null
  ) {
    setLoading(true);
    let url = `${process.env.REACT_APP_SERVER_URL}/user/registerwithlinkedin`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          linkedinCode: code,
          linkedinAccessTokenClient: linkedinAccessTokenClient
            ? linkedinAccessTokenClient
            : null,
        }),
      });

      if (response.status !== 200) {
        alert("something went wrong!!");
      }

      let data = await response.json();

      if (data?.newUser) {
        localStorage.setItem("linkedinAccessToken", data.linkedinAccessToken);
        localStorage.setItem(
          "linkedinURNId",
          data.linkedinURNId ? data.linkedinURNId : null
        );
        const newUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          linkedinAccessToken: data.linkedinAccessToken,
          linkedinURNId: data.linkedinURNId ? data.linkedinURNId : null,
        };
        setlinkData(newUser);
      } else if (data?.oldUser) {
        localStorage.setItem("linkedinAccessToken", data.linkedinAccessToken);
        localStorage.setItem(
          "linkedinURNId",
          data.linkedinURNId ? data.linkedinURNId : null
        );
        setlinkData(data.oldUser);
      }
    } catch (error) {
      console.log(error);
      setLinkedin(false);
      navigate(`/event/${eventsId.params.eventId}/linkedin`);
    }

    setLoading(false);
  }

  function handleLinkedinPost() {
    setLoading(true);
    // linkedinURNid can be passed here from different page where login is going to happen
    let linkedinAccessToken = localStorage.getItem("linkedinAccessToken");
    let linkedinURNid = localStorage.getItem("linkedinURNid");
    console.log(linkedinAccessToken, linkedinURNid);
    if (!linkedinAccessToken && linkedinURNid) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/user/linkedin-auto-post`,
          { linkedinURNid: linkedinURNid, eventId: singleEvent._id },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${linkedinAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data.message === "success") {
            setShared(true);
          } else {
            setLinkedin(false);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
      alert("something went wrong! Please try again"); //access-token or urnid not found
    }
  }

  useEffect(() => {
    if (linkData?.email) {
      handleLinkedinPost();
    }
  }, [linkData]);

  useEffect(() => {
    if (linkedin) {
      setLoading(true);
      linkedInLogin();
      setLoading(false);
    }
  }, [linkedin]);

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [popup]);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(window.location.href);
  };

  const closeCopy = () => {
    setPopup(false);
    setCopied("");
  };

  return (
    <>
      <Helmet>
        <title>Warpbay - {singleEvent?.title}</title>
        <link rel="icon" href={`${singleEvent?.coverImage}`} />
        <meta name="type" property="og:type" content="article" />
        <meta
          name="title"
          property="og:title"
          content={`${singleEvent?.title}`}
        />
        <meta
          name="description"
          property="og:description"
          content={`${singleEvent?.shortDescription}`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${singleEvent?.coverImage}`}
        />
        <meta name="url" property="og:url" content="https://app.warpbay.com" />
      </Helmet>

      {/* {share Popup} */}
      {popup && (
        <div className="">
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed w-[100%] md:left-0"
            onClick={closeCopy}
          ></div>
          <div
            className={`mymd:w-[400px] h-[230px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
          >
            <div className="h-[35px] font-semibold text-[20px] border-b-2 outline-offset-4 relative">
              Share This Event
              <span
                className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px]"
                onClick={closeCopy}
              >
                <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px]">
                  x
                </div>
              </span>
              <div className="h-[85px] flex justify-evenly items-center mt-2">
                <LandingSocialBtn />
              </div>
              <div className="h-[80px]">
                <span className="font-normal text-[15px]">Share the link:</span>
                <div className="flex justify-evenly">
                  <input
                    type="text"
                    readOnly={true}
                    className="mymd:w-[280px] w-full rounded-lg border-gray-300 shadow font-light text-[14px]"
                    value={window.location.href}
                    onClick={handleCopy}
                  />
                  <button
                    className="border rounded-lg bg-primary text-white text-[14px] ml-2 w-[75px] border-none"
                    onClick={handleCopy}
                  >
                    {copied === "" ? "Copy" : "Copied"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.nav_parent}>
        <nav className={styles.navbar}>
          <div className={styles.logo}>
            <img src="/svgs/logo.svg" alt="logo" /> Warpbay
          </div>
          <ul className={styles.menu_ul}>
            <li className={styles.nav_home}>
              <p
                onClick={() =>
                  window.open(`${window.location.origin}/login`, "_blank")
                }
              >
                Login
              </p>
            </li>
          </ul>
        </nav>
      </div>

      <div className="pt-0 mymd:pt-18 mymd:mb-0 mx-auto mymd:grid place-items-center w-[93vw] mymd:max-w-[1440px] mymd:w-full mb-[100px] ">
        {/* {Navbar}   */}

        {/* {title and coverImage} */}
        <div className="mymd:sticky mymd:top-0 z-20 mymd:bg-white mymd:mt-[0px] mb-[12px] mymd:h-[65px] mymd:grid place-items-center mt-[65px]">
          <div className="mymd:mr-0 mymd:w-[824px] mymd:flex mymd:mt-[0px] mt-0 items-center justify-between ">
            <h1 className="text-[22px] mymd:text-[24px] font-semibold">
              {" "}
              {singleEvent?.title}
            </h1>
            <div>
              <div className="hidden mymd:flex text-primary font-[600] items-center justify-between w-64">
                <span
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setPopup(true);
                  }}
                >
                  {" "}
                  <img
                    src="/svgs/SharePurple.svg"
                    alt="share"
                    className="mr-2"
                  />{" "}
                  Share
                </span>
                <span
                  className="flex items-center cursor-pointer"
                  onClick={() => setGoogleCal(true)}
                >
                  <img
                    src="/svgs/calendar_monthcalendar.svg"
                    alt="calendar"
                    className="mr-2"
                  />
                  Add to calendar
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.img_div}>
          <img
            src={singleEvent?.coverImage}
            alt=""
            className={"w-full h-full rounded-md object-cover"}
          />
        </div>
        <div className="bg-white">
          {/* {event blocks} */}
          <div className="mymd:flex justify-between mymd:h-[136px] mymd:w-[824px] w-[100%] mymd:mb-4">
            {/* {1st item} */}
            <div className="mymd:w-[250px] bg-[#F5F5F5] rounded-xl mymd:p-3.5 flex h-[56px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center overflow-hidden">
              <span className="text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0 font-medium">
                Event By
              </span>
              <div className="flex items-center mymd:mt-2 ml-7 mymd:ml-0">
                <div className="ml-2 mymd:ml-0">
                  {singleEvent.primaryOrganizer?.profilePicture ? (
                    <div className="h-8 w-8 rounded-full object-cover mr-0">
                      <img
                        src={singleEvent?.primaryOrganizer.profilePicture}
                        alt="user"
                        className="h-8 w-8 rounded-full object-cover mr-0"
                      />
                    </div>
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center mr-0 text-white text-lg font-medium uppercase">
                      {singleEvent?.primaryOrganizer.organization.charAt(0)}
                    </div>
                  )}
                </div>
                <span className="mymd:line-clamp-2 line-clamp-1 font-normal ml-1.5">
                  {singleEvent?.primaryOrganizer.organization}
                </span>
              </div>
            </div>

            {/* {2nd item} */}
            <div className="mymd:w-[300px] bg-[#F5F5F5] rounded-xl mymd:my-0 mymd:mx-2 my-2 mymd:p-3.5 flex h-[88px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center overflow-hidden">
              <span className="font-medium text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0 ">
                Date & Time
              </span>
              <div>
                <span className="flex items-center cursor-pointer my-3 font-normal">
                  {" "}
                  <img
                    src="/svgs/calender.svg"
                    alt="calendar"
                    className="mr-2"
                  />{" "}
                  {new Date(singleEvent?.startDate)
                    .toString()
                    .substring(0, 10)
                    .slice(-10) ===
                  new Date(singleEvent?.endDate)
                    .toString()
                    .substring(0, 10)
                    .slice(-10) ? (
                    <>
                      {new Date(singleEvent?.startDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        xmas95
                      )}
                    </>
                  ) : (
                    <>
                      {new Date(singleEvent?.startDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        new Date(singleEvent.startDate)
                      )}{" "}
                      to {new Date(singleEvent?.endDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        new Date(singleEvent?.endDate)
                      )}
                    </>
                  )}
                </span>
                <span className="flex items-center cursor-pointer font-normal ">
                  {" "}
                  <img src="/svgs/Accesstime.svg" alt="time" className="mr-2" />
                  <div className="line-clamp-1">
                    {moment(singleEvent?.startDate).format("LT")} to{" "}
                    {moment(singleEvent?.endDate).format("LT")}
                  </div>
                </span>
              </div>
            </div>

            {/* {3rd item} */}
            <div className="mymd:w-[414px] bg-[#F5F5F5] rounded-xl mymd:p-3.5 flex h-[88px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center pr-2 mymd:pr-0 overflow-hidden">
              <span className="font-medium text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0">
                Location
              </span>
              <span
                className="flex cursor-pointer font-normal mymd:w-[302px] text-primary underline mymd: mt-[10px] ml-7 mymd:ml-0"
                onClick={() => {
                  window.open(singleEvent?.location?.landmark, "_blank");
                }}
              >
                <img
                  src="/svgs/Location.svg"
                  alt="location"
                  className="mr-1 h-[24px]"
                />
                <div className="line-clamp-2">
                  {singleEvent.location?.addressLine1},{" "}
                  {singleEvent.location?.city}, {singleEvent.location?.state}
                </div>
              </span>
            </div>
            <div className="mymd:hidden flex text-primary justify-around h-10 mt-4 font-bold text-xs">
              <span
                className="flex items-center justify-center cursor-pointer border w-[43%] rounded border-primary"
                onClick={() => {
                  setPopup(true);
                }}
              >
                {" "}
                <img
                  src="/svgs/SharePurple.svg"
                  alt="share"
                  className="mr-2"
                />{" "}
                Share
              </span>
              <span
                className="flex items-center cursor-pointer justify-center border w-[43%] rounded border-primary"
                onClick={() => setGoogleCal(true)}
              >
                <img
                  src="/svgs/calendar_monthcalendar.svg"
                  alt="calendar"
                  className="mr-2"
                />
                Add to calendar
              </span>
            </div>
          </div>
        </div>

        {/* {tabs} */}
        <div className="">
          {loading && <div>Loading...</div>}
          {!loading && (
            <>
              {!shared && (
                <>
                  <div
                    className="bg-[#066093] w-full cursor-pointer flex items-center mt-4 rounded-[5px] gap-x-2 text-white text-md font-semibold"
                    onClick={() => setLinkedin(true)}
                  >
                    <div className="w-12 h-12 bg-[#0b78b7] rounded-tl-lg rounded-bl-lg grid place-items-center">
                      <svg
                        className="inline-block align-middle ml-0 rounded-lg"
                        width="30px"
                        height="30px"
                        viewBox="0 0 40 40"
                        version="1.1"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g id="LinkedIn" fill="#FFFFFF">
                            <g
                              id="Fill-6-+-Fill-7"
                              transform="translate(6.000000, 5.000000)"
                            >
                              <path
                                d="M3.44222222,0 C5.34,0 6.88,1.54111111 6.88,3.44 C6.88,5.34 5.34,6.88111111 3.44222222,6.88111111 C1.53666667,6.88111111 0,5.34 0,3.44 C0,1.54111111 1.53666667,0 3.44222222,0 L3.44222222,0 Z M0.471111111,9.48888889 L6.41,9.48888889 L6.41,28.5777778 L0.471111111,28.5777778 L0.471111111,9.48888889 Z"
                                id="Fill-6"
                              ></path>
                              <path
                                d="M10,9.47333333 L15.6866667,9.47333333 L15.6866667,12.0833333 L15.7688889,12.0833333 C16.56,10.5822222 18.4955556,9 21.3811111,9 C27.3888889,9 28.4988889,12.9522222 28.4988889,18.0933333 L28.4988889,28.5622222 L22.5666667,28.5622222 L22.5666667,19.2788889 C22.5666667,17.0655556 22.5288889,14.2177778 19.4844444,14.2177778 C16.3966667,14.2177778 15.9255556,16.63 15.9255556,19.1211111 L15.9255556,28.5622222 L10,28.5622222 L10,9.47333333"
                                id="Fill-7"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="text-[13px] px-[10px]">
                      Share event on LinkedIn
                    </span>
                  </div>
                </>
              )}
              {shared && (
                <div className="flex gap-2 items-center mt-4 h-max justify-center">
                  <img src="/svgs/Checkcircle.svg" alt="success" className="" />
                  <span className="text-[18px]  font-[500] text-center">
                    Post shared successfully
                  </span>
                </div>
              )}
            </>
          )}
        </div>

        {/* {GooogleCalender Popup} */}
        {googleCal && (
          <GoogleCalendar
            singleEvent={singleEvent}
            setGoogleCal={setGoogleCal}
          />
        )}
        {/* {footer} */}
        <div className={styles.footer_box}>
          <p className="hover:text-primary">Help</p>
          <p
            onClick={() => navigate("/events")}
            className="hover:text-primary hover:font-[500]"
          >
            Events
          </p>
          <p></p>
          <p className="hover:text-primary">Mail Us</p>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
