import React, { useEffect, useState } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { patchRequest } from "../../../../utils/API/api.ts";
import { useReactToPrint } from "react-to-print";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture.jsx";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions.js";
import socketIOClient from "socket.io-client";
const ENDPOINT = "http://localhost:5001";

function TransactionsTableItem({ registrations, setRegistrations }) {
  const dispatch = useDispatch();
  const [badgeUrl, setBadgeUrl] = useState("");
  const eventsId = useMatch("events/:eventId/*");
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  const socket = socketIOClient(ENDPOINT);
  //checkout and checkin Ateendee
  const checkInAttendee = async (attendee, btnText) => {
    try {
      const res = await patchRequest(
        `attendee/${attendee._id}/${eventsId.params.eventId}?val=${btnText}&location=${location}`,
        {}
      );
      const updatedAttendee = [{ ...attendee, attendee: [res.data.data] }];
      const newArray = registrations.map((obj) => {
        if (obj._id !== updatedAttendee[0]._id) {
          return obj;
        }
        return updatedAttendee[0];
      });

      setRegistrations(newArray);
      if (res.status === 200 && btnText === "checkin") {
        socket.emit("checkin", {
          eventId: eventsId.params.eventId,
          user: res.data.userData,
        });
        dispatch(
          showToast({
            message: "Attendee Checked In",
            category: "success",
          })
        );
      } else if (res.status === 200 && btnText === "checkout") {
        dispatch(
          showToast({
            message: "Attendee Checked Out",
            category: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Failed to check in attendee",
            category: "danger",
          })
        );
      }
    } catch (err) {
      console.log(err.response.data.message, "Something Went Wrong");
      dispatch(
        showToast({
          message: err.response.data.message,
          category: "danger",
        })
      );
    }
  };

  //print badge
  const handlePrint = (url) => {
    setBadgeUrl(url);
  };

  useEffect(() => {
    if (badgeUrl) {
      PrintImage();
    }
  }, [badgeUrl]);

  const PrintImage = useReactToPrint({
    content: () => {
      const imgElement = document.createElement("img");
      imgElement.src = badgeUrl;
      imgElement.style.width = "97%";
      imgElement.style.height = "97%";
      imgElement.style.display = "block";
      const containerElement = document.createElement("div");
      containerElement.style.display = "flex";
      containerElement.style.justifyContent = "center";
      containerElement.style.alignItems = "center";
      containerElement.style.width = "100%";
      containerElement.style.height = "100%";
      containerElement.appendChild(imgElement);
      return containerElement;
    },
    onAfterPrint: () => {
      setBadgeUrl("");
    },
  });

  function getEventSpecificData(attendee) {
    return Array.isArray(attendee.attendee)
      ? attendee.attendee[0]?.eventSpecificData
      : attendee.attendee?.eventSpecificData;
  }
  function isCheckedIn(eventSpecificData) {
    return eventSpecificData?.some(
      (data) =>
        data.eventId === eventsId.params.eventId &&
        data.isCheckedInData?.some(
          (isCheckedInData) =>
            isCheckedInData.location === location && isCheckedInData.isCheckedIn
        )
    );
  }

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find(
      (data) => data.eventId === eventsId.params.eventId
    );
  }

  const getLatestCheckinTime = (attendee) => {
    const eventSpecificData = getEventSpecificData(attendee);
    const eventData = getEventData(eventSpecificData);
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === location)
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn : null;
    }
  };

  function formatCheckInTime(checkInData) {
    if (checkInData) {
      const utcDate = new Date(checkInData.entry);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);

      return dateString;
    }
    return null;
  }

  const getLabel = (attendee) => {
    const eventSpecificData = getEventSpecificData(attendee);
    const eventData = getEventData(eventSpecificData);
    return eventData.label
      ? eventData.label.charAt(0).toUpperCase() +
          eventData.label.slice(1, eventData.label.length)
      : "N/A";
  };

  return (
    <>
      <div className="overflow-x-auto hidden mymd:block h-[370px] border rounded-t-sm">
        <table className="table-auto w-full">
          {/* Table header */}
          <thead className="text-xs font-semibold uppercase text-slate-500  border-b border-slate-200">
            <tr>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">Attendee</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                <div className="font-semibold text-left ">Designation</div>
              </th>

              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">email</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap ">
                <div className="font-semibold text-left ">Label</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">
                  Last Check-In Time
                </div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">Check in/out</div>
              </th>
              <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                <div className="font-semibold text-left">Badge</div>
              </th>
            </tr>
          </thead>
          {/* Table body */}
          <tbody className="text-sm divide-y divide-slate-200 border-b border-slate-200">
            {registrations &&
              registrations.length > 0 &&
              registrations.map((attendee) => {
                return (
                  <tr key={attendee?._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-1 ">
                      <div className="flex items-center">
                        <div className="w-9 h-9 shrink-0 mr-2 sm:mr-3">
                          {attendee.profilePicture ? (
                            <img
                              alt="Profile"
                              src={attendee.profilePicture}
                              className="rounded-full w-[34px] h-[34px] object-cover "
                            />
                          ) : (
                            <div className="">
                              <DefaultProfilePicture
                                firstName={attendee.firstName}
                                lastName={attendee.lastName}
                                style={{
                                  width: "34px",
                                  height: "34px",
                                  borderRadius: "300px",
                                  fontSize: "12px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <p className="font-medium text-slate-800 max-w-[140px] ">
                          {attendee.firstName} {attendee.lastName}
                        </p>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-1">
                      <p className="text-left line-clamp-1 max-w-[140px] ">
                        {attendee.jobTitle}, {attendee.organization}
                      </p>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                      <div className="text-left">{attendee.email}</div>
                    </td>
                    <td className="pr-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                      <div className=" bg-gray-200 rounded-[12px] text-center px-[2px] font-[500] text-black text-[11px]">
                        {getLabel(attendee)}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-1">
                      <p className="text-left">
                        {formatCheckInTime(getLatestCheckinTime(attendee)) ||
                          "N/A"}
                      </p>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                      {isCheckedIn(getEventSpecificData(attendee)) ? (
                        <>
                          <button
                            className={`h-[30px] w-[90px] 
                         bg-danger
                         font-[500]  text-white
                         rounded-[4px]`}
                            onClick={() =>
                              checkInAttendee(attendee, "checkout")
                            }
                          >
                            Check Out
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className={`h-[30px] w-[90px] bg-primary
                        font-[500]  text-white
                         rounded-[4px]`}
                            onClick={() => checkInAttendee(attendee, "checkin")}
                          >
                            Check In
                          </button>
                        </>
                      )}
                    </td>
                    <td className="px-1 first:pl-5 last:pr-5 py-1 whitespace-nowrap">
                      <p
                        className="font-[500] cursor-pointer text-[12px] text-primary hover:underline"
                        onClick={() => {
                          const eventSpecificData =
                            getEventSpecificData(attendee);
                          const eventData = getEventData(eventSpecificData);
                          if (eventData) {
                            handlePrint(eventData.badgeUrl);
                          }
                        }}
                      >
                        Print Badge
                      </p>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      <div className="mymd:hidden">
        <div className="pt-[20px] pb-[0px] mymd:pt-0 mymd:mt-[80px] mymd:w-[730px] mymd:grid mymd:grid-cols-2 mymd:gap-2 mymd:items-start ">
          {registrations && registrations?.length > 0 ? (
            registrations.map((attendee) => (
              <>
                <div
                  key={attendee?._id}
                  className=" bg-white mb-4 rounded-[10px] p-[16px] mymd:border mymd:w-[350px] mymd:mr-[15px] border border-[#EDEDED] relative"
                >
                  <div className="flex items-center">
                    {attendee.profilePicture ? (
                      <img
                        alt="Profile"
                        src={attendee.profilePicture}
                        className="rounded-full sm:w-[40px] sm:h-[40px] w-[40px] h-[40px] object-cover mr-3"
                      />
                    ) : (
                      <div className="mr-2">
                        <DefaultProfilePicture
                          firstName={attendee.firstName}
                          lastName={attendee.lastName}
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "300px",
                            fontSize: "14px",
                          }}
                        />
                      </div>
                    )}

                    <div className="">
                      <div className="sm:text-[14px] text-[13px] font-semibold pt-[5px] ">
                        {attendee.firstName} {attendee.lastName}
                      </div>
                      <div className="text-[12px] font-medium py-[0px] text-gray-500 line-clamp-2  ">
                        {attendee.jobTitle}, {attendee.organization}
                      </div>
                      <div className=" text-[12px] text-gray-600 font-[500]">
                        {getLabel(attendee)}
                      </div>
                      <div className=" text-[12px] text-gray-600 font-[500]">
                        {formatCheckInTime(getLatestCheckinTime(attendee)) ||
                          "N/A"}
                      </div>
                    </div>
                    <div className="absolute right-[20px] top-[16px] ">
                      <img
                        alt="print"
                        src="/svgs/print.svg"
                        className="w-[27px] cursor-pointer"
                        onClick={() => {
                          const eventSpecificData =
                            getEventSpecificData(attendee);
                          const eventData = getEventData(eventSpecificData);
                          if (eventData) {
                            handlePrint(eventData.badgeUrl);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    {isCheckedIn(getEventSpecificData(attendee)) ? (
                      <>
                        <button
                          className={`h-[35px]  w-full  bg-danger
                      font-[500]  text-white
                      rounded-[4px]`}
                          onClick={() => checkInAttendee(attendee, "checkout")}
                        >
                          Check Out
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`h-[35px]  w-full  bg-primary
                           font-[500]  text-white
                           rounded-[4px]`}
                          onClick={() => checkInAttendee(attendee, "checkin")}
                        >
                          Check In
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ))
          ) : (
            <div className="grid w-full place-items-center h-[250px]">
              <div>
                <img
                  src="/svgs/nullState.svg"
                  alt=""
                  className="w-[200px] h-[200px]"
                />
                <p className="text-[15px] font-[500] text-[#717171]  text-center">
                  Nothing here...
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionsTableItem;
