import React, { useState } from "react";
import StallTable from "./StallTable";
import AddStall from "./AddStall";

const Stalls = () => {
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  return (
    <div className="w-full md:w-[860px] md:ml-[0px] md:mt-[27px] pb-[200px]">
      <div className="font-[600] text-[21px] text-black flex justify-between items-center mb-2">
        Stalls
        <div className="w-[110px]">
          <div
            className="primary_submit"
            onClick={() => {
              setIsEdit(false);
              setOpen(true);
            }}
          >
            Add Stall
          </div>
        </div>
      </div>

      {/* stall availability bear/shell */}
      <div className="flex items-center gap-[20px] w-full">
        {/* Bare */}
        <div className="w-[300px] h-[100px] border border-[#EDEDED] rounded-[10px] p-[16px] flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Bare</p>
            <div className="flex gap-1.5">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.34323 14.7278L3.5148 17.5563L7.05033 21.0918L20.4854 7.65678L16.9498 4.12124L14.8285 6.24257L16.2427 7.65678L14.8285 9.07099L13.4143 7.65678L11.293 9.7781L13.4143 11.8994L12.0001 13.3136L9.87876 11.1923L7.75744 13.3136L9.17165 14.7278L7.75744 16.1421L6.34323 14.7278ZM17.6569 1.99992L22.6067 6.94967C22.9972 7.3402 22.9972 7.97336 22.6067 8.36389L7.75744 23.2131C7.36692 23.6037 6.73375 23.6037 6.34323 23.2131L1.39348 18.2634C1.00295 17.8729 1.00295 17.2397 1.39348 16.8492L16.2427 1.99992C16.6332 1.6094 17.2664 1.6094 17.6569 1.99992Z"
                  fill="black"
                  fill-opacity="0.5"
                />
              </svg>
              <span className="text-black text-opacity-[0.5] text-[15px] mt-[1px] font-normal">
                12 sizes
              </span>
            </div>
          </div>

          <div className="text-[#2ECC71] font-medium">
            20/30 stalls available
          </div>
        </div>

        {/* Shell */}
        <div className="w-[300px] h-[100px] border border-[#EDEDED] rounded-[10px] p-[16px] flex flex-col justify-between">
          <div className="flex justify-between items-center">
            <p className="font-semibold">Shell</p>
            <div className="flex gap-1.5">
              <svg
                width="22"
                height="22"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.34323 14.7278L3.5148 17.5563L7.05033 21.0918L20.4854 7.65678L16.9498 4.12124L14.8285 6.24257L16.2427 7.65678L14.8285 9.07099L13.4143 7.65678L11.293 9.7781L13.4143 11.8994L12.0001 13.3136L9.87876 11.1923L7.75744 13.3136L9.17165 14.7278L7.75744 16.1421L6.34323 14.7278ZM17.6569 1.99992L22.6067 6.94967C22.9972 7.3402 22.9972 7.97336 22.6067 8.36389L7.75744 23.2131C7.36692 23.6037 6.73375 23.6037 6.34323 23.2131L1.39348 18.2634C1.00295 17.8729 1.00295 17.2397 1.39348 16.8492L16.2427 1.99992C16.6332 1.6094 17.2664 1.6094 17.6569 1.99992Z"
                  fill="black"
                  fill-opacity="0.5"
                />
              </svg>
              <span className="text-black text-opacity-[0.5] text-[15px] mt-[1px] font-normal">
                5 sizes
              </span>
            </div>
          </div>

          <div className="text-[#2ECC71] font-medium">
            20/30 stalls available
          </div>
        </div>
      </div>

      <div className="flex my-3 items-center gap-2">
        <div className="border border-[#F4F6F9] bg-[#F4F6F9] py-1.5 px-4 w-[110px] h-[37px] rounded-[10px] text-black text-opacity-[0.5] font-medium flex items-center justify-center gap-1 text-[14px]">
          Sort By
          <img
            src="/svgs/arrow.svg"
            alt="arrow"
            className="w-[18px] font-[600] cursor-pointer rotate-180"
            // onMouseEnter={() => setTriggerLabel(true)}
            // onMouseLeave={() => setTriggerLabel(false)}
          />
        </div>

        <div className="flex items-center">
          <div className="flex gap-2 items-center">
            <div className="text-[12px] font-normal text-black flex justify-center items-center gap-2">
              <label htmlFor="whatsapp" className="">
                Dimensions in meters
              </label>
              <div className="inline-flex relative items-center cursor-pointer">
                <input
                  type="checkbox"
                  id={"whatsapp"}
                  onChange={() => {}}
                  checked={true}
                  className="sr-only peer"
                />
                <span className="w-[37px] h-[20px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <StallTable isEdit={isEdit} setIsEdit={setIsEdit} setOpen={setOpen} />
      <AddStall
        open={open}
        setOpen={setOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </div>
  );
};

export default Stalls;
