import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import { getRequest } from "../../../utils/API/api.ts";
import AttendeeCSVUpload from "./BulkUploadAttendee";
import { useMatch } from "react-router-dom";
import Modal from "../../../common/modals/Modal";
import "react-tooltip/dist/react-tooltip.css";
import RegistrationTabs from "./RegistrationTabs";
import ApprovalPending from "./approvalPending/ApprovalPending";
import JSZip from "jszip";
import axios from "axios";
import MoreDetails from "./approvalPending/MoreDetails";

function Registrations() {
  const eventsId = useMatch("/events/:eventId");
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState({
    registerdTotal: 0,
    filteredTotal: 0,
  });
  const [attendedRegistrations, setAttendedRegistrations] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [next, setNext] = useState({
    registerdNext: "",
    filteredNext: "",
  });
  const [previous, setPrevious] = useState({
    registerdPrevious: "",
    filteredPrevious: "",
  });
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Registered");
  const event = useSelector((state) => state.eventData);
  const searchValue = useSelector((state) => state.searchRegistration);
  const [totalPages, setTotalPages] = useState({
    registerdTotalPages: 0,
    filteredTotalPages: 0,
  });
  const [currentPage, setCurrentPage] = useState({
    registeredCurrent: 1,
    filteredCurrent: 1,
  });
  const [selectedLabel, setSelectedLabel] = useState({
    registerdLabel: "",
    filterdLabel: "",
  });
  const [open, setOpen] = useState(false);
  const [singleAttendee, setSingleAttendee] = useState([]);

  // UseEffect for initial registrations fetching
  useEffect(() => {
    if (eventsId.params.eventId && !isBulkUpload && event?.title) {
      getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId, isBulkUpload, event?.title]);
  //UseEffect for search related functionality

  useEffect(() => {
    async function fetchData() {
      setNext(null);

      const data = await getRequest(
        `/attendee/${eventsId.params.eventId}/search/attendee?name=${searchValue.value}&search=${tab}`
      );
      if (tab === "Registered") {
        setRegistrations([...data.data.registrations.attendees]);
      } else {
        setAttendedRegistrations([...data.data.registrations.attendees]);
      }
    }

    if (searchValue.value) {
      fetchData();
    } else if (searchValue.value === "" || searchValue.value === " ") {
      getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue.value, tab]);

  //fetch all registered event attendees
  const getAllEventAttendees = async (route) => {
    const response = await getRequest(route || next);
    if (
      registrations.length === 0 &&
      (searchValue.value === "" || searchValue.value === " ")
    ) {
      setRegistrations(response.data?.attendees);
    } else {
      setRegistrations(response.data?.attendees);
    }
    setNext({ ...next, registerdNext: response?.data?.next });
    setPrevious({ ...previous, registerdPrevious: response?.data?.previous });
    setTotal({
      ...total,
      registerdTotal: response?.data?.totalAttendees[0]?.total,
    });
    setTotalPages({
      ...totalPages,
      registerdTotalPages: response.data?.totalPages,
    });
    setCurrentPage({
      ...currentPage,
      registeredCurrent: response.data?.currentPage,
    });
    dispatch({
      type: UPDATE_EVENT,
      payload: {
        attendees: [response.data?.attendees],
      },
    });
  };

  //fetch all attendee and not attendee attendees
  const getAttendedAttendees = async (route) => {
    const response = await getRequest(route);
    setNext({ ...next, filteredNext: response?.data?.next });
    setPrevious({ ...previous, filteredPrevious: response?.data?.previous });
    setTotal({ ...total, filteredTotal: response?.data?.totalAttendees });
    setTotalPages({
      ...totalPages,
      filteredTotalPages: response.data?.totalPages,
    });
    setCurrentPage({
      ...currentPage,
      filteredCurrent: response.data?.currentPage,
    });

    setAttendedRegistrations(response?.data?.registrations);
  };

  const handleDownload = async () => {
    setLoading(true);
    let dataToDownload;
    if (tab === "Registered") {
      const response = await getRequest(
        `/attendee/${eventsId.params.eventId}?limit=${0}&label=${
          selectedLabel?.registerdLabel ? selectedLabel.registerdLabel : null
        }`
      );
      if (response.status === 200) {
        dataToDownload = response.data.attendees;
      }
    } else if (tab === "Attended" || tab === "NotAttended") {
      let url;
      if (tab === "Attended") {
        url = `attendee/${
          eventsId.params.eventId
        }/attended/?hasAttended=true&location=eventCheckin&limit=0&label=${
          selectedLabel?.filterdLabel ? selectedLabel.filterdLabel : null
        }`;
      } else if (tab === "NotAttended") {
        url = `attendee/${
          eventsId.params.eventId
        }/attended/?hasAttended=false&location=eventCheckin&limit=0&label=${
          selectedLabel?.filterdLabel ? selectedLabel.filterdLabel : null
        }`;
      }
      const response = await getRequest(url);
      if (response.status === 200) {
        dataToDownload = response?.data?.registrations;
      }
    }

    const fields = [
      "firstName",
      "lastName",
      "email",
      "countryCode",
      "mobile",
      "organization",
      "country",
      "state",
      "city",
      "jobTitle",
      "registrationDate",
      "lastCheckInTime",
      "label",
    ];

    let dynamicCsvFields = await getAdditionalForm();

    const additionalDataKeys = dynamicCsvFields?.map((key) => key?.fieldName);

    fields.push(...additionalDataKeys);

    const headerRow = fields.join(",");
    const dataRows = dataToDownload.map((row) => {
      let country = "N/A";
      let state = "N/A";
      let city = "N/A";
      if (row.location && row.location.country && row.location.country.name) {
        country = row.location.country.name;
      }
      if (row.location && row.location.city && row.location.city.name) {
        city = row.location.city.name;
      }
      if (row.location && row.location.state && row.location.state.name) {
        state = row.location.state.name;
      }
      if (row.attendee) {
        const eventSpecificData = Array.isArray(row.attendee)
          ? row.attendee[0]?.eventSpecificData
          : row.attendee?.eventSpecificData;
        const matchingData = getEventData(eventSpecificData);

        if (matchingData) {
          const registrationDate = formateDateTime(matchingData.timeStamp);
          const lastCheckInTime = formateDateTime(
            getLatestCheckinTime(matchingData)
          );
          const label = matchingData.label;

          return fields.map((field) => {
            if (field === "registrationDate") {
              return registrationDate;
            } else if (field === "lastCheckInTime") {
              return lastCheckInTime;
            } else if (field === "country") {
              return country || "N/A";
            } else if (field === "state") {
              return state || "N/A";
            } else if (field === "city") {
              return city || "N/A";
            } else if (field === "label") {
              return label || "N/A";
            } else if (additionalDataKeys?.includes(field)) {
              const additionalDataValue =
                matchingData &&
                matchingData.additionalData &&
                typeof matchingData.additionalData === "object"
                  ? matchingData.additionalData[field] || "N/A"
                  : "N/A";
              if (additionalDataValue && Array.isArray(additionalDataValue)) {
                const arrayLabels = additionalDataValue.map((ans) => {
                  if (ans && ans.label) {
                    const value = `${String(ans.label).replace(
                      /[\n\r]/g,
                      " "
                    )}`;
                    return value || "N/A";
                  } else {
                    return "N/A";
                  }
                });

                let finalValue = arrayLabels.join("-");
                return `${finalValue}`;
              } else if (
                additionalDataValue &&
                typeof additionalDataValue === "object"
              ) {
                return "N/A";
              } else {
                if (additionalDataValue) {
                  let value =
                    String(additionalDataValue).replace(/[\n\r]/g, " ") ||
                    "N/A";

                  value = `"${value}"` || "N/A";

                  return value || "N/A";
                } else {
                  return "N/A";
                }
              }
            } else {
              return row[field] || "N/A";
            }
          });
        }
      }
    });

    const csvContent =
      "data:text/csv;charset=utf-8," + [headerRow, ...dataRows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${tab}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  const formateDateTime = (data) => {
    if (data === null) {
      return "N/A";
    } else {
      const utcDate = new Date(data);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Asia/Kolkata",
      };
      const dateString = utcDate.toLocaleDateString("en-IN", options);
      return dateString;
    }
  };

  const getAdditionalForm = async () => {
    const response = await getRequest(
      `/event/${eventsId.params.eventId}/additionalForm`
    );
    if (response.data[0]?.form) {
      let updatedRegForm = response.data[0]?.form?.filter((form) => {
        return (
          form.inputType !== "city" &&
          form.inputType !== "country" &&
          form.inputType !== "state"
        );
      });
      return updatedRegForm;
    }
  };

  const getLatestCheckinTime = (eventData) => {
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn.entry : null;
    }
  };

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find((data) => data.eventId === event?._id);
  }

  const downloadAllBadges = async () => {
    setLoading(true);

    try {
      const response = await getRequest(`/attendee/${eventsId.params.eventId}`);

      if (response.status === 200) {
        const dataToDownload = response.data.attendees;
        const imageUrls = dataToDownload.map((userData) =>
          getEventSpecificDataBadge(userData)
        );
        const zip = new JSZip();

        const fetchAndAddImage = async (url, index) => {
          try {
            const response = await axios.get(url, {
              responseType: "arraybuffer",
            });
            const imageBlob = new Blob([response.data], { type: "image/png" });
            zip.file(`image_${index}.png`, imageBlob);
          } catch (error) {}
        };

        const fetchImagePromises = imageUrls.map(fetchAndAddImage);

        await Promise.all(fetchImagePromises);

        zip.generateAsync({ type: "blob" }).then((content) => {
          const zipBlob = new Blob([content], { type: "application/zip" });
          const zipUrl = URL.createObjectURL(zipBlob);

          const a = document.createElement("a");
          a.href = zipUrl;
          a.download = "images.zip";
          a.style.display = "none";

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(zipUrl);

          setLoading(false);
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  function getEventSpecificDataBadge(attendee) {
    const eventSpecificData = attendee?.attendee[0]?.eventSpecificData?.find(
      (data) => data.eventId === eventsId.params.eventId
    );
    return eventSpecificData?.badgeUrl;
  }

  return (
    <>
      {loading && (
        <>
          <div className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-40 fixed w-[100%] md:left-0"></div>

          <div
            className={`mymd:w-[400px] h-[210px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
          >
            <div className="w-full h-full flex justify-center items-center flex-col">
              <svg
                className="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <p className="mt-3">Downloading data...</p>
            </div>
          </div>
        </>
      )}
      <div className="md:ml-[0px] md:mt-[0px] md:w-[100%] pb-12">
        <div className="bg-white sticky top-0 z-30 border-t">
          <div className="font-[600] w-[335px] md:w-[900px] text-[24px] pt-3 text-black flex justify-between items-center">
            <p className="text-[21px]">Registrations</p>

            <div className="flex items-center gap-3">
              <div
                className="bg-gray-200 py-1.5 px-1.5 rounded-sm cursor-pointer hover:bg-gray-100"
                title="Download Badges"
                onClick={downloadAllBadges}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="black"
                  width="16px"
                  height="16px"
                  viewBox="0 0 24 24"
                  id="download"
                >
                  <path d="M21,14a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v4a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14Zm-9.71,1.71a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l4-4a1,1,0,0,0-1.42-1.42L13,12.59V3a1,1,0,0,0-2,0v9.59l-2.29-2.3a1,1,0,1,0-1.42,1.42Z"></path>
                </svg>
              </div>
              <div
                className="bg-primary text-white h-8 py-1 px-2 text-[14px] my-1 font-semibold rounded-sm w-[145px] flex items-center text-center justify-center cursor-pointer hover:opacity-95"
                onClick={handleDownload}
              >
                Export Data
                <img
                  src="/svgs/downloadIcon.svg"
                  className="w-[18px] ml-2 font-[500]"
                  alt="download"
                />
              </div>
              <button
                className="bg-gray-200 text-gray-800 h-8 py-1 px-2 text-[14px]  my-1 font-semibold rounded-sm w-[130px] hover:bg-gray-100"
                onClick={() => {
                  setIsBulkUpload(true);
                }}
              >
                Bulk Upload
              </button>

              <div
                className="text-gray-800 text-[14px] bg-gray-200 py-1 px-2 rounded-sm cursor-pointer hover:bg-gray-100"
                title="Extended View"
                onClick={() =>
                  window.open(
                    `${window.location.origin}/events/${eventsId.params.eventId}/registrations/extended-view`,
                    "_blank"
                  )
                }
              >
                <i className="fa-solid fa-up-right-from-square"></i>
              </div>
            </div>
          </div>
          <form className="flex md:hidden w-[340px] mx-auto items-center my-4">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className=" w-full ">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
                placeholder="Search"
                required={true}
                onChange={(e) => {
                  if (e.target.value) {
                    setSearch(e.target.value);
                  } else {
                    setRegistrations([]);
                    getAllEventAttendees(
                      `/attendee/${eventsId.params.eventId}`
                    );
                  }
                }}
              ></input>
            </div>
          </form>
          <div className="flex flex-row items-center w-[340px] md:w-[900px] mt-0  text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 justify-between">
            <ul className="flex flex-wrap -mb-px">
              <li
                className="mr-2 cursor-pointer"
                onClick={() => setTab("Registered")}
              >
                <span
                  className={`inline-block px-0 py-4 ${
                    tab === "Registered"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px] `}
                >
                  Registered&nbsp;
                  {tab === "Registered" ? (
                    <>
                      (
                      {total?.registerdTotal === undefined
                        ? 0
                        : total?.registerdTotal}
                      )
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                className="mr-2 cursor-pointer"
                onClick={() => setTab("Attended")}
              >
                <span
                  className={`inline-block px-4 py-4 ${
                    tab === "Attended"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                  onClick={() => {
                    getAttendedAttendees(
                      `attendee/${eventsId.params.eventId}/attended/?hasAttended=true&location=eventCheckin`
                    );
                  }}
                >
                  Attended{" "}
                  {tab === "Attended" ? (
                    <>
                      (
                      {total?.filteredTotal === undefined
                        ? 0
                        : total?.filteredTotal}
                      )
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              <li
                className="mr-2 cursor-pointer"
                onClick={() => setTab("NotAttended")}
              >
                <span
                  className={`inline-block py-4 ${
                    tab === "NotAttended"
                      ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                      : ""
                  } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                  onClick={() => {
                    getAttendedAttendees(
                      `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin`
                    );
                  }}
                >
                  Not attended{" "}
                  {tab === "NotAttended" ? (
                    <>
                      (
                      {total?.filteredTotal === undefined
                        ? 0
                        : total?.filteredTotal}
                      )
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </li>
              {event?.isRegistrationApproval && (
                <li
                  className="mr-2 cursor-pointer"
                  onClick={() => setTab("ApprovalPending")}
                >
                  <span
                    className={`inline-block py-4 px-4 ${
                      tab === "ApprovalPending"
                        ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                        : ""
                    } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[14px]`}
                    onClick={() => {
                      getAttendedAttendees(
                        `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin`
                      );
                    }}
                  >
                    Approval pending
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="w-[335px] md:w-[100%]">
          {tab === "Registered" && registrations ? (
            <RegistrationTabs
              registrations={registrations}
              setRegistrations={setRegistrations}
              getAllEventAttendees={getAllEventAttendees}
              searchValue={searchValue}
              eventsId={eventsId}
              total={total.registerdTotal}
              tab={tab}
              next={next?.registerdNext}
              previous={previous?.registerdPrevious}
              totalPages={totalPages.registerdTotalPages}
              currentPage={currentPage.registeredCurrent}
              getAttendedAttendees={getAttendedAttendees}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
              setOpen={setOpen}
              setSingleAttendee={setSingleAttendee}
            />
          ) : tab === "Attended" && attendedRegistrations ? (
            <RegistrationTabs
              registrations={attendedRegistrations}
              getAllEventAttendees={getAllEventAttendees}
              searchValue={searchValue}
              setRegistrations={setAttendedRegistrations}
              eventsId={eventsId}
              total={total.filteredTotal}
              tab={tab}
              next={next?.filteredNext}
              previous={previous?.filteredPrevious}
              totalPages={totalPages.filteredTotalPages}
              currentPage={currentPage.filteredCurrent}
              getAttendedAttendees={getAttendedAttendees}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
              setOpen={setOpen}
              setSingleAttendee={setSingleAttendee}
            />
          ) : tab === "NotAttended" && attendedRegistrations ? (
            <RegistrationTabs
              registrations={attendedRegistrations}
              getAllEventAttendees={getAllEventAttendees}
              setRegistrations={setAttendedRegistrations}
              searchValue={searchValue}
              eventsId={eventsId}
              total={total.filteredTotal}
              tab={tab}
              next={next?.filteredNext}
              previous={previous?.filteredPrevious}
              totalPages={totalPages.filteredTotalPages}
              currentPage={currentPage.filteredCurrent}
              getAttendedAttendees={getAttendedAttendees}
              selectedLabel={selectedLabel}
              setSelectedLabel={setSelectedLabel}
              setOpen={setOpen}
              setSingleAttendee={setSingleAttendee}
            />
          ) : tab === "ApprovalPending" ? (
            <ApprovalPending
              registrations={registrations}
              setRegistrations={setRegistrations}
              eventsId={eventsId}
            />
          ) : (
            <></>
          )}
        </div>
        <div className="">
          <Modal isOpen={isBulkUpload} setIsOpen={setIsBulkUpload}>
            <AttendeeCSVUpload
              setIsBulkUpload={setIsBulkUpload}
              getAllEventAttendees={getAllEventAttendees}
            />
          </Modal>
        </div>
      </div>
      <MoreDetails
        open={open}
        setOpen={setOpen}
        singleAttendee={singleAttendee}
        approvalPending={"false"}
        eventsId={eventsId}
        funName={
          tab === "Registered" ? getAllEventAttendees : getAttendedAttendees
        }
        apiRoute={
          tab === "Registered"
            ? `/attendee/${eventsId.params.eventId}/?page=${
                currentPage.registeredCurrent
              }&limit=10&label=${
                selectedLabel?.registerdLabel
                  ? selectedLabel.registerdLabel
                  : null
              }`
            : tab === "Attended"
            ? `/attendee/${
                eventsId.params.eventId
              }/attended/?hasAttended=true&location=eventCheckin&page=${
                currentPage.filteredCurrent
              }&limit=10&label=${
                selectedLabel?.filterdLabel ? selectedLabel.filterdLabel : null
              }`
            : `/attendee/${
                eventsId.params.eventId
              }/attended/?hasAttended=false&location=eventCheckin&page=${
                currentPage.filteredCurrent
              }&limit=10&label=${
                selectedLabel?.filterdLabel ? selectedLabel.filterdLabel : null
              }`
        }
      />
    </>
  );
}

export default Registrations;
