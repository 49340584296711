import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import DefaultProfilePicture from "../../../../common/defaultProfilePicture/DefaultProfilePicture";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import EditAdditionalQuestions from "./EditAdditionalQuestions";
import EditRegistrationQuestions from "./EditRegistrationQuestions";
import { getRequest } from "../../../../utils/API/api.ts";

export default function MoreDetails({
  open,
  setOpen,
  singleAttendee,
  updateApprovalStatus,
  eventsId,
  approvalPending,
  apiRoute,
  funName,
}) {
  const dispatch = useDispatch();
  const [editAddtionalInfo, setEditAddtionalInfo] = useState(false);
  const [editRegistrationQuestions, setEditRegistrationQuestions] =
    useState(false);
  const [editName, setEditName] = useState("additionalInfo");
  const [userData, setUserData] = useState({
    registrationQuestions: [],
    additionalInfo: [],
  });
  const [triggerLabel, setTriggerLabel] = useState(false);
  const [value1, setValue1] = useState("");
  const [regForm, setRegForm] = useState([]);

  const options = [
    {
      label: "Sponsor",
      value: 1,
    },
    {
      label: "Speaker",
      value: 2,
    },
    {
      label: "Government",
      value: 3,
    },
    {
      label: "Delegate",
      value: 4,
    },
    {
      label: "Media",
      value: 5,
    },
    {
      label: "Organizer",
      value: 6,
    },
    {
      label: "Volunteer",
      value: 7,
    },
    {
      label: "None",
      value: 8,
    },
  ];

  function formatTime(regDate) {
    if (regDate) {
      const utcDate = new Date(regDate);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);

      return dateString;
    }
    return null;
  }

  const getAdditionalUserData = (userInfo) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/attendee/${eventsId.params.eventId}/${singleAttendee?.user?._id}/getAdditionalUserData?currentStatus=${singleAttendee?.status}`
      )
      .then(function (response) {
        const updatedUserData = {
          registrationQuestions: response?.data?.additionalData?.additionalData,
          additionalInfo: userInfo,
        };
        setUserData(updatedUserData);
      })
      .catch(function (error) {
        dispatch(
          showToast({
            message: "Some error occured",
            category: "danger",
          })
        );
      });
  };

  useEffect(() => {
    if (open && eventsId.params.eventId && singleAttendee?.user?.organization) {
      let userInfo = [
        { key: "Company Name", value: singleAttendee?.user?.organization },
        { key: "Designation", value: singleAttendee?.user?.jobTitle },
        {
          key: "Mobile",
          value:
            singleAttendee?.user?.mobile && singleAttendee?.user?.countryCode
              ? `${singleAttendee?.user?.countryCode} ${singleAttendee?.user.mobile}`
              : singleAttendee?.user?.mobile,
        },
        {
          key: "Country",
          value: singleAttendee?.user?.location?.country?.name,
        },
        { key: "State", value: singleAttendee?.user?.location?.state?.name },
        { key: "City", value: singleAttendee?.user?.location?.city?.name },
      ];
      const eventSpecificData = getEventSpecificData(singleAttendee?.user);
      const eventData = getEventData(eventSpecificData);
      setValue1(
        eventData?.label
          ? eventData.label.charAt(0).toUpperCase() + eventData.label.slice(1)
          : "None"
      );
      getAdditionalUserData(userInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId, singleAttendee, open]);

  useEffect(() => {
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      if (response.data[0]?.form) {
        let updatedRegForm = response.data[0]?.form?.filter((form) => {
          return (
            form.inputType !== "city" &&
            form.inputType !== "country" &&
            form.inputType !== "state"
          );
        });
        setRegForm(updatedRegForm);
      }
    };
    if (eventsId.params.eventId) {
      getAdditionalForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsId]);

  const updateUserLabel = (label) => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/updateEventSpecificData/${eventsId.params.eventId}/${singleAttendee?.user?._id}`,
        {
          userLabel: label,
        }
      )
      .then(function (response) {
        setValue1(label);
        dispatch(
          showToast({
            message: "Label Updated Successfully",
            category: "success",
          })
        );
        funName(apiRoute);
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "Something went wrong!!",
            category: "danger",
          })
        );
      });
  };

  const transformQuestionKey = (key) => {
    const doesInclude = regForm?.filter((form) => key === form?.fieldName);
    if (doesInclude) {
      return doesInclude[0]?.label;
    }
    // if (key.includes("_")) {
    //   const words = key.split("_");
    //   const capitalizedWords = words.map(
    //     (word) => word.charAt(0).toUpperCase() + word.slice(1)
    //   );
    //   return capitalizedWords.join(" ");
    // } else {
    //   return key.charAt(0).toUpperCase() + key.slice(1);
    // }
  };

  function getEventSpecificData(attendee) {
    return Array.isArray(attendee?.attendee)
      ? attendee?.attendee[0]?.eventSpecificData
      : attendee?.attendee?.eventSpecificData;
  }

  function getEventData(eventSpecificData) {
    return eventSpecificData?.find(
      (data) => data.eventId === eventsId.params.eventId
    );
  }

  const fetchRegistrationDate = (attendee) => {
    if (attendee) {
      const eventSpecificData = getEventSpecificData(attendee);
      const eventData = getEventData(eventSpecificData);
      const formattedDate = formatTime(eventData?.timeStamp);

      return formattedDate;
    }
  };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          <div className="flex items-center gap-3 w-full">
                            <p className="line-clamp-1 w-full">
                              {singleAttendee?.user?.firstName
                                .charAt(0)
                                .toUpperCase() +
                                singleAttendee?.user?.firstName?.slice(
                                  1,
                                  singleAttendee.user?.firstName?.length
                                )}{" "}
                              {singleAttendee?.user?.lastName}
                            </p>

                            {approvalPending !== "false" &&
                            singleAttendee.status === "approved" ? (
                              <p className="p-[2px] font-[500] text-green-500 grid place-items-center w-[80px] bg-green-100 rounded-[12px] text-[12px] mr-2 h-[27px]">
                                Approved
                              </p>
                            ) : singleAttendee.status === "rejected" ? (
                              <p className="p-[2px] font-[500] text-red-500 grid place-items-center w-[80px] bg-red-100 rounded-[12px] text-[12px] mr-2 h-[27px]">
                                Rejected
                              </p>
                            ) : singleAttendee.status === "pending" ? (
                              <p className="p-[2px] font-[500] text-yellow-500 grid place-items-center w-[80px] bg-yellow-100 rounded-[12px] text-[12px] mr-2 h-[27px]">
                                Pending
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[55px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <div className="flex justify-between items-center">
                          <div className="grid grid-cols-[45px_1fr] gap-[5px]">
                            {singleAttendee?.user?.profilePicture ? (
                              <img
                                src={singleAttendee?.user?.profilePicture}
                                className="w-[40px] h-[40px] rounded-[50%] object-cover"
                                alt="profile"
                              />
                            ) : (
                              <div className=" ">
                                <DefaultProfilePicture
                                  firstName={singleAttendee?.user?.firstName}
                                  lastName={singleAttendee?.user?.lastName}
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "300px",
                                    fontSize: "15px",
                                  }}
                                />
                              </div>
                            )}

                            <span className="text-[16px] font-[500]">
                              <p>
                                {singleAttendee?.user?.firstName
                                  .charAt(0)
                                  .toUpperCase() +
                                  singleAttendee?.user?.firstName?.slice(
                                    1,
                                    singleAttendee.user?.firstName?.length
                                  )}{" "}
                                {singleAttendee?.user?.lastName}
                              </p>
                              <p className="text-[13px] text-slate-500 line-clamp-1 w-full font-[450]">
                                {singleAttendee?.user?.email}
                              </p>
                            </span>
                          </div>
                          {approvalPending !== "false" &&
                            singleAttendee?.status !== "pending" && (
                              <div className="relative">
                                {singleAttendee.status === "approved" ? (
                                  <p
                                    className="p-[2px] font-[500] text-green-500 grid place-items-center w-[100px] bg-green-100 rounded-[12px] text-[12px] h-[27px] cursor-pointer"
                                    onMouseEnter={() => setTriggerLabel(true)}
                                    onMouseLeave={() => setTriggerLabel(false)}
                                  >
                                    <span className="flex">
                                      Approved{" "}
                                      <img
                                        src="/svgs/arrow.svg"
                                        alt="arrow"
                                        className="w-[15px] font-[600] ml-[5px]  rotate-180"
                                      />
                                    </span>
                                  </p>
                                ) : singleAttendee.status === "rejected" ? (
                                  <p
                                    className="p-[2px] font-[500] text-red-500 grid place-items-center w-[100px] bg-red-100 rounded-[12px] text-[12px] h-[27px] cursor-pointer"
                                    onMouseEnter={() => setTriggerLabel(true)}
                                    onMouseLeave={() => setTriggerLabel(false)}
                                  >
                                    <span className="flex">
                                      Rejected{" "}
                                      <img
                                        src="/svgs/arrow.svg"
                                        alt="arrow"
                                        className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                                      />
                                    </span>
                                  </p>
                                ) : singleAttendee.status === "pending" ? (
                                  <p
                                    className="p-[2px] font-[500] text-yellow-500 grid place-items-center w-[100px] bg-yellow-100 rounded-[12px] text-[12px] h-[27px] cursor-pointer"
                                    onMouseEnter={() => setTriggerLabel(true)}
                                    onMouseLeave={() => setTriggerLabel(false)}
                                  >
                                    <span className="flex">
                                      Pending{" "}
                                      <img
                                        src="/svgs/arrow.svg"
                                        alt="arrow"
                                        className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                                      />
                                    </span>
                                  </p>
                                ) : (
                                  <></>
                                )}

                                {triggerLabel && (
                                  <div
                                    className="absolute w-[100px] top-[27px] pt-1 bg-transperent z-20 right-[0px]"
                                    onMouseEnter={() => setTriggerLabel(true)}
                                    onMouseLeave={() => {
                                      setTriggerLabel(false);
                                    }}
                                  >
                                    <div
                                      className="bg-red-100 h-[30px] rounded-[12px]"
                                      style={{
                                        boxShadow:
                                          "0px 0px 14px rgba(165, 94, 234, 0.2)",
                                      }}
                                    >
                                      {singleAttendee.status === "approved" ? (
                                        <div
                                          className=" hover:bg-red-200 h-[27px] cursor-pointer text-red-500 grid place-items-center bg-red-100 rounded-[12px] text-[13px] font-[500]"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateApprovalStatus(
                                              singleAttendee.user?._id,
                                              "rejected",
                                              singleAttendee.status
                                            );
                                          }}
                                        >
                                          Reject
                                        </div>
                                      ) : (
                                        <div
                                          className=" hover:bg-green-200 h-[27px] cursor-pointer text-green-500 grid place-items-center bg-green-100 rounded-[12px] text-[13px] font-[500]"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateApprovalStatus(
                                              singleAttendee.user?._id,
                                              "approved",
                                              singleAttendee.status
                                            );
                                          }}
                                        >
                                          Approve
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                        <div className="w-full mt-3 border-b pb-3">
                          <p className="text-[14px] text-gray-400 font-normal">
                            Registered
                          </p>
                          <div className="mt-[1px] font-medium">
                            {/* Aug 2023, 7:43 PM */}
                            {fetchRegistrationDate(singleAttendee?.user) ||
                              "N/A"}
                          </div>
                        </div>

                        <div className="mt-3 border-b pb-1  ">
                          <div className="flex justify-between ">
                            <span className="text-[20px] font-medium">
                              User Info
                            </span>

                            <div
                              className="bg-gray-100 rounded-[10px] p-1 text-[14px] font-medium w-[70px] gap-1 flex items-center justify-center text-gray-600 cursor-pointer hover:shadow-md"
                              onClick={() => {
                                setEditName("additionalInfo");
                                setEditAddtionalInfo(true);
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <mask
                                  id="mask0_1395_148"
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="24"
                                  height="24"
                                >
                                  <rect width="20" height="20" fill="#D9D9D9" />
                                </mask>
                                <g mask="url(#mask0_1395_148)">
                                  <path
                                    d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                    fill="#C5C5C7"
                                  />
                                </g>
                              </svg>
                              <span>Edit</span>
                            </div>
                          </div>

                          <div className="mt-2 text-[15px]">
                            {userData?.additionalInfo?.map((data) => {
                              return (
                                <>
                                  {data?.value && (
                                    <div className="text-gray-400 font-normal text-[15px] mb-2">
                                      {data?.key}:{" "}
                                      <span className="font-medium text-black">
                                        {data?.value}
                                      </span>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                            <div className="text-gray-400 font-normal text-[15px] mb-2 flex items-center">
                              Label:
                              <span className="font-medium text-black">
                                <select
                                  id="label"
                                  value={value1}
                                  onChange={(e) =>
                                    updateUserLabel(e.target.value)
                                  }
                                  className={`w-full
                                    peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[30px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block px-2.5 py-0 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 ml-2 cursor-pointer`}
                                >
                                  {options.map((o, index) => (
                                    <option
                                      key={o.value}
                                      value={o.label}
                                      className="text-[14px]"
                                    >
                                      {o.label}
                                    </option>
                                  ))}
                                </select>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* 
                        {regForm?.map((form) => {
                            form?.inputType === "multi-select" ? 
                           
                                      <div className="bg-gray-100 p-1 mb-1 rounded-[10px]">
                                            {ans?.label}
                                          </div>
                              :
                              <></>
                        })} */}

                        {userData?.registrationQuestions && (
                          <div className="mt-2.5 whitespace-normal text-wrap">
                            <div className="flex justify-between ">
                              <span className="text-[20px] font-medium">
                                Registration Questions
                              </span>

                              <div
                                className="bg-gray-100 rounded-[10px] p-1 text-[14px] font-medium w-[70px] gap-1 flex items-center justify-center text-gray-600 cursor-pointer hover:shadow-md"
                                onClick={() => {
                                  setEditName("registrationQuestions");
                                  setEditRegistrationQuestions(true);
                                }}
                              >
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="mask0_1395_148"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="20"
                                      height="20"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_1395_148)">
                                    <path
                                      d="M19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                      fill="#C5C5C7"
                                    />
                                  </g>
                                </svg>
                                <span>Edit</span>
                              </div>
                            </div>

                            <div className="mt-3 text-[15px]  text-wrap">
                              {Object.entries(
                                userData?.registrationQuestions
                              ).map(([question, answer]) => (
                                <div
                                  key={question}
                                  className="mb-3 whitespace-normal	"
                                >
                                  {transformQuestionKey(question) && (
                                    <>
                                      <div className="text-gray-400 font-normal text-[13px] mb-0.5 text-wrap">
                                        {transformQuestionKey(question)}{" "}
                                      </div>
                                      <div className="font-medium text-black text-[15px]">
                                        {Array.isArray(answer) ? (
                                          answer?.map((ans) => {
                                            return (
                                              <div className="bg-gray-100 p-1 mb-1 rounded-[10px]">
                                                {ans?.label}
                                              </div>
                                            );
                                          })
                                        ) : typeof answer === "object" ? (
                                          "N/A"
                                        ) : /(^|[^<])(https?:\/\/[\S]+(\b|$)|www\.[^\s]+(\b|$))(\W|$)/gi.test(
                                            answer
                                          ) ? (
                                          <div className="whitespace-normal break-words">
                                            <a
                                              href={answer.trim()}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="text-primary break-all"
                                              style={{
                                                wordWrap: "break-word",
                                              }}
                                            >
                                              Open File
                                            </a>
                                          </div>
                                        ) : (
                                          <div className="whitespace-normal break-words">
                                            {answer || "Not Answered"}
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}

                        {singleAttendee?.status === "pending" && (
                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-between items-center gap-4">
                            <input
                              value={"Approve"}
                              type="submit"
                              className="h-[42px] w-full bg-green-500 rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer hover:bg-green-600"
                              onClick={(e) => {
                                e.stopPropagation();
                                updateApprovalStatus(
                                  singleAttendee.user?._id,
                                  "approved",
                                  singleAttendee.status
                                );
                              }}
                            />
                            <input
                              value={"Reject"}
                              type="submit"
                              className="h-[42px] w-full bg-[#E74C3C] rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer hover:bg-red-500"
                              onClick={(e) => {
                                e.stopPropagation();
                                updateApprovalStatus(
                                  singleAttendee.user?._id,
                                  "rejected",
                                  singleAttendee.status
                                );
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <EditAdditionalQuestions
        open={editAddtionalInfo}
        setOpen={setEditAddtionalInfo}
        userData={singleAttendee?.user}
        additionalQuestions={userData?.registrationQuestions}
        eventId={eventsId.params.eventId}
        transformQuestionKey={transformQuestionKey}
        setMoreDetails={setOpen}
        apiRoute={apiRoute}
        funName={funName}
        editName={editName}
      />

      <EditRegistrationQuestions
        open={editRegistrationQuestions}
        setOpen={setEditRegistrationQuestions}
        userData={singleAttendee?.user}
        additionalQuestions={userData?.registrationQuestions}
        eventId={eventsId.params.eventId}
        transformQuestionKey={transformQuestionKey}
        setMoreDetails={setOpen}
        apiRoute={apiRoute}
        funName={funName}
        editName={editName}
      />
    </>
  );
}
