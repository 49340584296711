import React, { useEffect, useRef, useState } from "react";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";

const RegistrationTabs = ({
  registrations,
  getAllEventAttendees,
  total,
  eventsId,
  tab,
  next,
  getAttendedAttendees,
  previous,
  totalPages,
  currentPage,
  selectedLabel,
  setSelectedLabel,
  setSingleAttendee,
  setOpen,
}) => {
  const targetRef = useRef([]);
  const [triggerLabel, setTriggerLabel] = useState(false);

  const showBadge = (index) => {
    targetRef.current[index].style.display = "block";
  };

  const hideBadge = (index) => {
    targetRef.current[index].style.display = "none";
  };
  const labelOptions = [
    "No labels",
    "Sponsor",
    "Speaker",
    "Government",
    "Delegate",
    "Media",
    "Organizer",
    "Volunteer",
  ];

  const getLatestCheckinTime = (eventData) => {
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn : null;
    }
  };

  function formatCheckInTime(checkInData) {
    if (checkInData) {
      const utcDate = new Date(checkInData.entry);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);
      return dateString;
    }
    return null;
  }

  useEffect(() => {
    if (tab !== "Registered") {
      setSelectedLabel({ ...selectedLabel, filterdLabel: "" });
    }
  }, [tab]);

  // useEffect(() => {
  //   let interval;
  //   if (tab === "Registered" && !selectedLabel.registerdLabel) {
  //     interval = setInterval(() => {
  //       getAllEventAttendees(
  //         `/attendee/${eventsId.params.eventId}?page=${currentPage}`
  //       );
  //     }, 20000);
  //   } else if (tab === "Attended" && !selectedLabel.filterdLabel) {
  //     interval = setInterval(() => {
  //       getAttendedAttendees(
  //         `attendee/${eventsId.params.eventId}/attended/?hasAttended=true&location=eventCheckin&limit=10&page=${currentPage}`
  //       );
  //     }, 20000);
  //   } else if (tab === "NotAttended" && !selectedLabel.filterdLabel) {
  //     interval = setInterval(() => {
  //       getAttendedAttendees(
  //         `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin&limit=10&page=${currentPage}`
  //       );
  //     }, 20000);
  //   }
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [tab, currentPage, selectedLabel]);

  return (
    <div className="flex-grow md:w-[100%]	">
      <table className="table-auto mb-[30px]">
        <thead className="">
          <tr className="">
            <th className="text-[12px] font-[600] text-left leading-[45px] w-[200px] flex">
              User
            </th>
            <th className="text-[12px] font-[600] text-left leading-[45px] w-[230px]">
              Contact Info
            </th>
            <th className="text-[12px] font-[600] text-left leading-[45px] w-[160px]">
              {tab === "Attended" ? "Last Check-in time" : "Registration date"}
            </th>
            <th className="text-[12px] font-[600] text-left leading-[45px] w-[90px]  relative">
              Role
            </th>
            <th className="text-[12px] font-[600] text-center  relative leading-[45px] w-[110px] flex justify-center items-center">
              Label
              <img
                src="/svgs/arrow.svg"
                alt="arrow"
                className="w-[15px] font-[600] ml-[5px] cursor-pointer rotate-180"
                onMouseEnter={() => setTriggerLabel(true)}
                onMouseLeave={() => setTriggerLabel(false)}
              />
              {triggerLabel && (
                <div
                  className="absolute w-[130px] top-[27px] pt-4 bg-transperent z-20"
                  onMouseEnter={() => setTriggerLabel(true)}
                  onMouseLeave={() => {
                    setTriggerLabel(false);
                  }}
                >
                  <div
                    className="bg-white border h-[128px]   rounded-lg overflow-y-scroll"
                    style={{
                      boxShadow: "0px 0px 14px rgba(165, 94, 234, 0.2)",
                    }}
                  >
                    {labelOptions?.map((label, index) => {
                      return (
                        <div
                          className="border-b hover:bg-gray-200 h-[40px] cursor-pointer"
                          key={index}
                          onClick={() => {
                            setTriggerLabel(false);

                            if (tab === "Registered") {
                              setSelectedLabel({
                                ...selectedLabel,
                                registerdLabel:
                                  label === "No labels" ? "" : label,
                              });
                              getAllEventAttendees(
                                `/attendee/${eventsId.params.eventId}?label=${
                                  label === "No labels" ? null : label
                                }`
                              );
                            } else {
                              setSelectedLabel({
                                ...selectedLabel,
                                filterdLabel:
                                  label === "No labels" ? "" : label,
                              });
                              getAttendedAttendees(
                                `/attendee/${
                                  eventsId.params.eventId
                                }/attended/?hasAttended=${
                                  tab === "Attended" ? "true" : "false"
                                }&location=eventCheckin&limit=${
                                  label === "No labels" ? "10" : "0"
                                }&label=${label === "No labels" ? null : label}`
                              );
                            }
                          }}
                        >
                          {label}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </th>
            <th className="text-[12px] font-[600] leading-[45px] text-center w-[90px]">
              Badge
            </th>
          </tr>
        </thead>
        {registrations?.length > 0 ? (
          <tbody className="md:w-[100%] ">
            <>
              {registrations.map((attendee, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr
                      className="h-[55px] border-b cursor-pointer hover:bg-[#f9fbff] hover:shadow-sm"
                      onClick={() => {
                        setSingleAttendee({
                          user: attendee,
                          status: "approved",
                        });
                        setOpen(true);
                      }}
                    >
                      <td className="text-[12px] font-[500]]">
                        <div className="grid grid-cols-[45px_1fr] gap-[1px]">
                          {attendee.profilePicture ? (
                            <img
                              src={attendee.profilePicture}
                              className="w-[32px] h-[32px] rounded-[50%] object-cover"
                              alt="profile"
                            />
                          ) : (
                            <div className=" ">
                              <DefaultProfilePicture
                                firstName={attendee.firstName}
                                lastName={attendee.lastName}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "300px",
                                  fontSize: "13px",
                                }}
                              />
                            </div>
                          )}

                          <span className="text-[12px] font-[400] ">
                            <p className="line-clamp-1 w-[140px]">
                              {attendee.firstName.charAt(0).toUpperCase() +
                                attendee.firstName.slice(
                                  1,
                                  attendee.firstName.length
                                )}
                              &nbsp;
                              {attendee.lastName}
                            </p>
                            <p className="text-[12px] text-slate-500 line-clamp-1 w-[140px] font-[450]">
                              {attendee.jobTitle && attendee.jobTitle},{" "}
                              {attendee.organization && attendee.organization}
                            </p>
                          </span>
                        </div>
                      </td>
                      <td className="text-[12px] font-[500]">
                        <div className="text-[12px] font-[400] grid grid-rows-2">
                          <p>{attendee.email && attendee.email}</p>
                          <span className="flex">
                            <p className="text-[12px] text-slate-500 font-[450]">
                              {attendee.countryCode && attendee.countryCode}
                              &nbsp;
                            </p>
                            <p className="text-[12px] text-slate-500 font-[450]">
                              {attendee.mobile && attendee.mobile}
                            </p>
                          </span>
                        </div>
                      </td>
                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, index) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                const utcDate = new Date(ele.timeStamp);
                                const options = {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "Asia/Kolkata",
                                };
                                const formatter = new Intl.DateTimeFormat(
                                  "en-IN",
                                  options
                                );
                                const dateString = formatter.format(utcDate);
                                return tab !== "Attended" ? (
                                  <td className="text-[12px] font-[400]">
                                    {ele?.timeStamp ? dateString : "N/A"}
                                  </td>
                                ) : (
                                  <td className="text-[12px] font-[400]">
                                    {formatCheckInTime(
                                      getLatestCheckinTime(ele)
                                    ) || "N/A"}
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, index) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                const utcDate = new Date(ele.timeStamp);

                                const options = {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "Asia/Kolkata",
                                };
                                const formatter = new Intl.DateTimeFormat(
                                  "en-IN",
                                  options
                                );
                                const dateString = formatter.format(utcDate);
                                return tab !== "Attended" ? (
                                  <td className="text-[12px] font-[400]">
                                    {ele?.timeStamp ? dateString : "N/A"}
                                  </td>
                                ) : (
                                  <td className="text-[12px] font-[400]">
                                    {formatCheckInTime(
                                      getLatestCheckinTime(ele)
                                    ) || "N/A"}
                                  </td>
                                );
                              }
                            }
                          )}

                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p
                                      className={`${
                                        ele.highestRole === "attendee"
                                          ? "bg-[#2D9CDB]"
                                          : ele.highestRole === "speaker"
                                          ? "bg-[#27AE60]"
                                          : ele.highestRole === "organiser"
                                          ? "bg-[#F2994A]"
                                          : "bg-primary"
                                      } rounded-[12px] p-[4px] text-white grid place-items-center w-[85%]`}
                                    >
                                      {ele.highestRole === "exhibitorAndSponsor"
                                        ? "Exhibitor"
                                        : ele.highestRole
                                            .charAt(0)
                                            .toUpperCase() +
                                          ele.highestRole.slice(
                                            1,
                                            ele.highestRole.length
                                          )}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p
                                      className={`${
                                        ele.highestRole === "attendee"
                                          ? "bg-[#2D9CDB]"
                                          : ele.highestRole === "speaker"
                                          ? "bg-[#27AE60]"
                                          : ele.highestRole === "organiser"
                                          ? "bg-[#F2994A]"
                                          : "bg-primary"
                                      } rounded-[12px] p-[4px] text-white grid place-items-center w-[85%]`}
                                    >
                                      {ele.highestRole === "exhibitorAndSponsor"
                                        ? "Exhibitor"
                                        : ele.highestRole
                                            .charAt(0)
                                            .toUpperCase() +
                                          ele.highestRole.slice(
                                            1,
                                            ele.highestRole.length
                                          )}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )}

                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px]">
                                      {ele?.label
                                        ? ele.label.charAt(0).toUpperCase() +
                                          ele.label.slice(1, ele.label.length)
                                        : "N/A"}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px]">
                                      {ele?.label
                                        ? ele.label.charAt(0).toUpperCase() +
                                          ele.label.slice(1, ele.label.length)
                                        : "N/A"}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )}

                      <td className="text-center relative">
                        <div
                          ref={(element) => {
                            targetRef.current[index] = element;
                          }}
                          className="absolute z-20 inline-block px-3 py-1 ml-10 mt-0 bg-transparent hidden w-[200px]"
                          onMouseEnter={() => showBadge(index)}
                          onMouseLeave={() => hideBadge(index)}
                        >
                          {attendee.attendee[0] === undefined
                            ? attendee.attendee?.eventSpecificData.map(
                                (items) => {
                                  if (
                                    items.eventId === eventsId.params.eventId
                                  ) {
                                    return (
                                      <img
                                        className="border rounded-t-xl register_img "
                                        src={items.badgeUrl}
                                        alt="badge"
                                      />
                                    );
                                  }
                                }
                              )
                            : attendee.attendee[0]?.eventSpecificData.map(
                                (items) => {
                                  if (
                                    items.eventId === eventsId.params.eventId
                                  ) {
                                    return (
                                      <img
                                        className="border rounded-t-xl register_img"
                                        src={items.badgeUrl}
                                        alt="badge"
                                      />
                                    );
                                  }
                                }
                              )}

                          <div className=" flex justify-evenly text-white">
                            <button
                              onClick={() => {
                                var win = window.open("");
                                attendee.attendee[0] === undefined
                                  ? attendee.attendee?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            win.document.write(
                                              '<html><head><style>img { display: block; margin: 0 auto; }</style></head><body><img src="' +
                                                items.badgeUrl +
                                                '" onload="window.print();window.close()" /></body></html>'
                                            )
                                          );
                                        }
                                      }
                                    )
                                  : attendee.attendee[0]?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            win.document.write(
                                              '<html><head><style>img { display: block; margin: 0 auto; }</style></head><body><img src="' +
                                                items.badgeUrl +
                                                '" onload="window.print();window.close()" /></body></html>'
                                            )
                                          );
                                        }
                                      }
                                    );

                                win.focus();
                              }}
                              className="w-[100%] bg-primary h-[30px] mr-2 rounded-bl-lg"
                            >
                              Print
                            </button>
                            <button
                              className="w-[100%] bg-primary h-[30px] rounded-br-lg"
                              onClick={() => {
                                attendee.attendee[0] === undefined
                                  ? attendee.attendee?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            window.open(
                                              `${items.badgeUrl} `,
                                              "_blank"
                                            )
                                          );
                                        }
                                      }
                                    )
                                  : attendee.attendee[0]?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            window.open(
                                              `${items.badgeUrl} `,
                                              "_blank"
                                            )
                                          );
                                        }
                                      }
                                    );
                              }}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                        <img
                          src="/svgs/IDCard.svg"
                          alt="IDCard"
                          className="ml-[35px] cursor-pointer"
                          onMouseEnter={() => showBadge(index)}
                          onMouseLeave={() => hideBadge(index)}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </>
          </tbody>
        ) : (
          <div className="grid w-full place-items-center  mt-[40px]">
            {/* <div>
              <img
                src="/svgs/nullState.svg"
                alt=""
                className="w-[] h-[100px]"
              />
              <p className="text-[15px] font-[500] text-[#717171] text-center mt-[15px]">
                Nothing here...
              </p>
            </div> */}
          </div>
        )}
      </table>

      {/* {!selectedLabel && ( */}
      <div className="flex items-center ml-0 w-full mx-auto py-[3px] fixed bottom-0 bg-white border-t-2 h-[60px]">
        <div className="text-[13px] mr-2">
          <span className="font-semibold text-[14px]">
            {registrations?.length}
          </span>{" "}
          of <span className="font-semibold text-[14px]">{total}</span> results
        </div>

        <button
          className={`text-center cursor-pointer text-${
            previous === null ? "gray-300" : "primary"
          } text-[23px]`}
          disabled={
            tab === "Registered"
              ? selectedLabel.registerdLabel
                ? true
                : false
              : selectedLabel.filterdLabel
              ? true
              : false || previous === null
          }
          onClick={async () => {
            if (tab === "Registered") {
              await getAllEventAttendees(previous);
            } else {
              await getAttendedAttendees(previous);
            }
          }}
        >
          {"<"}
        </button>

        <div className="mx-3 cursor-pointer">
          <select
            id="title"
            disabled={
              tab === "Registered"
                ? selectedLabel.registerdLabel
                  ? true
                  : false
                : selectedLabel.filterdLabel
                ? true
                : false
            }
            name="title"
            required
            value={currentPage}
            onChange={(e) => {
              if (tab === "Registered") {
                getAllEventAttendees(
                  `/attendee/${eventsId.params.eventId}/?page=${e.target.value}&limit=10`
                );
              } else {
                const url = next || previous;
                const regex = /\?(.*?)&location/;
                const match = url.match(regex);
                const queryString = match ? match[1] : "";

                getAttendedAttendees(
                  `/attendee/${eventsId.params.eventId}/attended/?${queryString}&location=eventCheckin&page=${e.target.value}&limit=10`
                );
              }
            }}
            className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
               `}
          >
            {Array.from({ length: totalPages }).map((_, index) => (
              <option
                key={index + 1}
                value={index + 1}
                className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
              >
                {index + 1}
              </option>
            ))}
          </select>
        </div>

        <button
          disabled={
            tab === "Registered"
              ? selectedLabel.registerdLabel
                ? true
                : false
              : selectedLabel.filterdLabel
              ? true
              : false || next === null
          }
          className={`text-center cursor-pointer text-${
            next === null ? "gray-300" : "primary"
          } text-[23px]`}
          onClick={async () => {
            if (tab === "Registered") {
              await getAllEventAttendees(next);
            } else {
              await getAttendedAttendees(next);
            }
          }}
        >
          {">"}
        </button>
        <p className="text-[14px] font-[500] ml-2">
          Sorted By:{" "}
          <span className="text-primary">
            {tab === "Registered"
              ? selectedLabel.registerdLabel
                ? selectedLabel.registerdLabel
                : "No labels"
              : selectedLabel.filterdLabel
              ? selectedLabel.filterdLabel
              : "No labels"}
          </span>
        </p>
      </div>
      {/* )} */}
    </div>
  );
};

export default RegistrationTabs;
