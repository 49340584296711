import React, { useEffect, useRef, useState } from "react";
// import { QrReader } from "react-qr-reader";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import { patchRequest } from "../../utils/API/api.ts";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastActions.js";
import QrScanner from "qr-scanner";

const RegistrationScan = ({
  registrations,
  setTriggerScan,
  triggerScan,
  setRegistrations,
  camera,
  setCamera,
}) => {
  const eventsId = useMatch("events/:eventId/*");
  const dispatch = useDispatch();
  const [cameras, setCameras] = useState([]);
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  let videoElemRef = useRef();
  var qrScanner;

  const checkInAttendee = async (attendee, val, uidParam) => {
    try {
      const res = await patchRequest(
        `attendee/${uidParam}/${eventsId.params.eventId}?val=${val}&location=${location}&userId=${uidParam}`
      );

      const updatedAttendee = [{ ...attendee[0], attendee: [res.data.data] }];
      const newArray = registrations.map((obj) => {
        if (obj._id !== updatedAttendee[0]._id) {
          return obj;
        }
        return updatedAttendee[0];
      });
      setRegistrations(newArray);
      if (res.status === 200 && triggerScan === "checkin") {
        dispatch(
          showToast({
            message: `${attendee[0].firstName} ${attendee[0].lastName} Checked In`,
            category: "success",
          })
        );
      } else if (res.status === 200 && triggerScan === "checkout") {
        dispatch(
          showToast({
            message: `${attendee[0].firstName} ${attendee[0].lastName} Checked Out`,
            category: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Failed to check in attendee",
            category: "danger",
          })
        );
      }
    } catch (err) {
      console.log(err.response.data, "Something Went Wrong");
      dispatch(
        showToast({
          message: err.response.data.message,
          category: "danger",
        })
      );
    }
  };

  useEffect(() => {
    let timerId;
    qrScanner = new QrScanner(
      videoElemRef.current,
      (result) => {
        const urlObj = new URL(result.data);
        const uidParam = urlObj.searchParams.get("uid");

        if (uidParam) {
          const attendee = registrations?.filter((attendee) => {
            return attendee._id === uidParam;
          });

          if (triggerScan === "checkout") {
            checkInAttendee(attendee, "checkout", uidParam);
            qrScanner.stop();
            timerId = setTimeout(() => {
              qrScanner.start();
            }, 500);
          } else {
            checkInAttendee(attendee, "checkin", uidParam);
            qrScanner.stop();
            timerId = setTimeout(() => {
              qrScanner.start();
            }, 500);
          }
        }
      },
      {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
        preferredCamera: camera,
        // calculateScanRegion: true,
        maxScansPerSecond: 10,
      }
    );

    qrScanner.start();
    return () => {
      clearTimeout(timerId);
      qrScanner.stop();
    };
  }, []);

  const changeCamera = async () => {
    setTriggerScan("");
    setCamera(camera === "environment" ? "user" : "environment");
    setTimeout(() => {
      setTriggerScan(triggerScan);
    }, 500);
  };

  const handleClose = () => {
    qrScanner?.destroy();
    setTriggerScan("");
  };

  useEffect(() => {
    const listCameras = async () => {
      try {
        const cameraList = await QrScanner.listCameras();
        setCameras(cameraList);
      } catch (error) {
        console.error("Error listing cameras:", error);
      }
    };

    listCameras();
  }, []);

  return (
    <>
      <div className="w-full flex justify-start items-center border-b-2 outline-offset-8 pb-3 relative mb-[20px]">
        <span className="text-[16px] font-[500]">Scan Badge QR Code</span>

        <img
          src="/svgs/switch-camera.svg"
          alt="camera"
          className="w-[25px] cursor-pointer ml-3"
          onClick={changeCamera}
          title="Switch Camera"
        />

        <span
          className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] hover:bg-gray-300"
          onClick={handleClose}
        >
          <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-[500] hover:font-[600]">
            x
          </div>
        </span>
      </div>

      <div className="pt-0 md:pt-0 md:mb-0 mx-auto grid place-items-center  w-full md:max-w-[1440px] md:w-full mb-14 h-[300px]">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`rounded-[15px] relative top-[10px] md:top-[10px] md:w-full md:h-full w-full border-[4px] border-[#d5d5d5] mx-auto`}
        ></video>
      </div>
    </>
  );
};

export default RegistrationScan;
