import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import TextInput from "../../../../common/inputElements/TextInput";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../../redux/actions/userActions";
import CountryCode from "../../../../common/countryCode/CountryCode";
import countryCodes from "../../../../utils/CountryCodes.json";

export default function EditAdditionalQuestions({
  open,
  setOpen,
  userData,
  eventId,
  setMoreDetails,
  apiRoute,
  funName,
  editName,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("+91");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);

  useEffect(() => {
    if (userData?.email) {
      reset({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        organization: userData?.organization,
        jobTitle: userData?.jobTitle,
        email: userData?.email,
        mobile: userData?.mobile,
        countryCode: userData?.countryCode || "",
        country: userData?.location?.country?.name,
        city: userData?.location?.city?.name,
        state: userData?.location?.state?.name,
      });
      if (userData?.countryCode) {
        setCountryCode(userData?.countryCode);
      } else {
        setCountryCode("");
      }
    }
  }, [userData]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const matchingCountry = countryCodes.find(
      (country) => country.dial_code === countryCode
    );
    const regex = /^[\d]{10}$/;
    if (editName === "additionalInfo" && !matchingCountry) {
      dispatch(
        showToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (editName === "additionalInfo" && !regex.test(data.mobile)) {
      dispatch(
        showToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      alert("please login!! access not found");
      navigate("/login");
    }

    let updatedUserData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
      organization: data?.organization,
      jobTitle: data?.jobTitle,
      email: data?.email,
      mobile: data?.mobile,
      countryCode: countryCode,
      location: {
        country: {
          ...userData?.location?.country,
          name: data?.country,
        },
        city: {
          ...userData?.location?.city,
          name: data?.city,
        },
        state: {
          ...userData?.location?.state,
          name: data?.state,
        },
      },
    };
    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/user/${userData._id}`,
        {
          ...updatedUserData,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        dispatch(getUserDetails({ accessToken: accessToken }));
        dispatch(
          showToast({
            message: "Attendee edited successfully",
            category: "success",
          })
        );
        funName(apiRoute);
        setOpen(false);
        setMoreDetails(false);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "something went wrong!!",
            category: "danger",
          })
        );
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[21px] pb-[25px] font-[500] text-gray-900 pt-5 flex items-center">
                          <div
                            className="cursor-pointer mr-2"
                            onClick={() => setOpen(false)}
                          >
                            <svg
                              width="23"
                              height="23"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 12H5"
                                stroke="#585858"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 19L5 12L12 5"
                                stroke="#585858"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          Edit Attendee Details
                        </Dialog.Title>
                      </div>

                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        id="additionalFields"
                      >
                        <div className="relative mt-[70px] flex-1 px-4 sm:px-6 mb-[70px]">
                          <TextInput
                            register={register}
                            type="text"
                            id={"firstName"}
                            label="First Name"
                            required
                          />
                          <TextInput
                            register={register}
                            type="text"
                            id={"lastName"}
                            label="Last Name"
                            required
                          />

                          <CountryCode
                            countryCode={countryCode}
                            setCountryCode={setCountryCode}
                            allCountryCode={allCountryCode}
                            setAllCountryCode={setAllCountryCode}
                          />

                          <TextInput
                            register={register}
                            type="tel"
                            id={"mobile"}
                            label="Phone Number"
                            required
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"organization"}
                            label="Company Name"
                            required
                          />
                          <TextInput
                            register={register}
                            type="text"
                            id={"jobTitle"}
                            label="Designation"
                            required
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"country"}
                            label="Country"
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"state"}
                            label="State"
                          />

                          <TextInput
                            register={register}
                            type="text"
                            id={"city"}
                            label="City"
                          />

                          <div className="fixed bottom-0 w-[87%] bg-white h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              name="additionalFields"
                              value={
                                isSubmitting ? "Loading..." : "Save Changes"
                              }
                              disabled={isSubmitting}
                              type="submit"
                              className="h-[42px] w-full bg-primary rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
