import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../../../common/inputElements/TextInput";
import throttle from "../../../utils/throttle";
import { useDispatch } from "react-redux";
import { showToast } from "../../../redux/actions/toastActions";

export default function AddCheckinLocation({
  open,
  setOpen,
  checkInLocations,
  setCheckInLocations,
  eventId,
  isEdit,
  setIsEdit,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [singleLocation, setSingleLocation] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit) {
      reset({
        locationName: singleLocation.locationName,
      });
    } else {
      reset({
        locationName: "",
      });
    }
  }, [singleLocation, isEdit]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    let updatedLocation = [];

    if (isEdit) {
      const newLocations = checkInLocations.map((location) => {
        if (location._id === singleLocation._id) {
          return { ...location, locationName: data.locationName };
        } else {
          return location;
        }
      });
      updatedLocation = newLocations;
    } else {
      const duplicateExists = checkInLocations?.some(
        (location) =>
          location.locationName.toLowerCase() ===
          data.locationName.toLowerCase()
      );

      if (duplicateExists) {
        dispatch(
          showToast({
            message: "Location with the same name exists",
            category: "danger",
          })
        );
        setIsSubmitting(false);
        return;
      }

      const newObject = { locationName: data.locationName };
      updatedLocation = [...checkInLocations, newObject];
    }

    setCheckInLocations(updatedLocation);
    try {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        alert("Unauthorised User");
      }

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/event/addVenueCheckinLocations/${eventId}?edit=${isEdit}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            locationName: data.locationName,
            oldLocationName: singleLocation.locationName,
            venueCheckinLocations: updatedLocation,
          }),
        }
      );

      const locationRes = await response.json();
      if (response.status !== 200) {
        throw new Error();
      }
      if (response.status === 200 && !isEdit) {
        dispatch(
          showToast({
            message: "Location Added Successfully",
            category: "success",
          })
        );
      } else {
        dispatch(
          showToast({
            message: "Location Edited",
            category: "success",
          })
        );
      }
      setIsEdit(false);
      // setOpen(false);
      reset();
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
      setIsSubmitting(false);
      dispatch(
        showToast({
          message: "Some error occured",
          category: "danger",
        })
      );
    }
  };

  // const deleteLocation = async (locationId) => {
  //   const updatedLocation = checkInLocations.filter((location) => {
  //     return location._id !== locationId;
  //   });

  //   try {
  //     const updatedEvent = await patchAuthenticatedRequest(
  //       `/event/${eventId}`,
  //       {
  //         checkinLocations: updatedLocation,
  //       }
  //     );
  //     setCheckInLocations(updatedLocation);
  //     if (updatedEvent.status === 200) {
  //       dispatch(
  //         showToast({
  //           message: "Location Deleted",
  //           category: "danger",
  //         })
  //       );
  //     }
  //     setOpen(false);
  //     reset();
  //     setIsSubmitting(false);
  //   } catch (err) {
  //     dispatch(
  //       showToast({
  //         message: "Failed to delete location",
  //         category: "danger",
  //       })
  //     );
  //     setOpen(false);
  //     setIsSubmitting(false);
  //   }
  // };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => {
                            setOpen(false);
                          }}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pt-5 pb-4 font-[600] text-gray-900">
                          {isEdit ? "Edit Location" : "Add Location"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[100px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <TextInput
                            register={register}
                            type="text"
                            id={"locationName"}
                            required
                            label={
                              !isEdit
                                ? "Add location for QR scan"
                                : "Edit location for QR scan"
                            }
                            placeholder="Enter location name"
                          />

                          <span className="text-[21px] pt-5 pb-4 font-[600] text-gray-900">
                            Added Locations:
                          </span>

                          {checkInLocations?.map((location) => {
                            return (
                              <div className="mt-[15px]" key={location._id}>
                                <p className="font-normal text-[15px] mb-[6px] ">
                                  {location.locationName}
                                </p>
                                <div className="flex items-center ">
                                  <input
                                    type="text"
                                    readOnly={true}
                                    className="w-full mymd:w-[280px] rounded-lg border-gray-300 shadow font-light text-[14px]"
                                    value={window.location.href}
                                    onClick={() => {
                                      const checkinLocation =
                                        location.locationName
                                          .toLowerCase()
                                          .replace(/\s+/g, "-");
                                      navigator.clipboard.writeText(
                                        `${window.location.origin}/events/${eventId}/registrations?location=${checkinLocation}&tab=Attendees&view=EventAttendees`
                                      );
                                      dispatch(
                                        showToast({
                                          message: "Link Copied to Clipboard",
                                          category: "success",
                                        })
                                      );
                                    }}
                                  />
                                  {/* <img
                                    src="/svgs/Delete.svg"
                                    alt="delete"
                                    className="w-6 h-6 cursor-pointer "
                                    onClick={() => deleteLocation(location._id)}
                                  /> */}
                                  <img
                                    src="/svgs/Edit.svg"
                                    alt="edit"
                                    className="w-6 h-6 cursor-pointer mx-[5px] mymd:mx-5"
                                    onClick={() => {
                                      setSingleLocation(location);
                                      setIsEdit(true);
                                    }}
                                  />
                                  <i
                                    className="fa-regular fa-copy cursor-pointer text-[#C5C5C7] font-[500] text-[20px]"
                                    onClick={() => {
                                      const checkinLocation =
                                        location.locationName
                                          .toLowerCase()
                                          .replace(/\s+/g, "-");
                                      navigator.clipboard.writeText(
                                        `${window.location.origin}/events/${eventId}/registrations?location=${checkinLocation}&tab=Attendees&view=EventAttendees`
                                      );
                                      dispatch(
                                        showToast({
                                          message: "Link Copied to Clipboard",
                                          category: "success",
                                        })
                                      );
                                    }}
                                  ></i>
                                </div>
                              </div>
                            );
                          })}

                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center">
                            {isEdit ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting
                                    ? "Loading..."
                                    : "Add New Location"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
