import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastActions";

const MultiChipInput = ({
  setInputValue,
  setChips,
  inputValue,
  chips,
  limit,
  required,
  label,
  note,
  style,
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      if (limit && chips.length < limit) {
        setChips([...chips, inputValue.trim()]);
      } else if (!limit) {
        setChips([...chips, inputValue.trim()]);
      } else {
        dispatch(
          showToast({
            message: `Only ${limit} can be added at max`,
            category: "danger",
          })
        );
      }
      setInputValue("");
    } else if (e.key === "Backspace" && inputValue === "" && chips.length > 0) {
      setChips(chips.slice(0, -1));
    }
  };

  const handleChipRemove = (chipIndex) => {
    const updatedChips = chips.filter((_, index) => index !== chipIndex);
    setChips(updatedChips);
  };

  const handleDivClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputBlur = () => {
    if (inputValue.trim() !== "") {
      if (chips.length < 3) {
        setChips([...chips, inputValue.trim()]);
      } else {
        dispatch(
          showToast({
            message: "Max three emails can be added",
            category: "danger",
          })
        );
      }
      setInputValue("");
    }
  };

  const inputWidth =
    (inputValue?.length ? inputValue?.length + 2 : 3) * 10 + "px";
  return (
    <div className="mb-9 relative" style={{ ...style }}>
      <label
        htmlFor="chips"
        className="inline-block pl-2 left-2 -top-5  text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
      >
        {required ? (
          <>
            {label}
            &nbsp;<span className="text-red-600">*</span>&nbsp;
            {limit ? `(Max limit: ${limit})` : ""}
          </>
        ) : (
          <>
            {label} &nbsp;{limit ? `(Max limit: ${limit})` : ""}
          </>
        )}
      </label>
      <p className="text-black text-opacity-[0.5] text-sm pt-0 ml-2 mb-1">
        {note ? note : "Press 'Enter' key to add more"}
      </p>
      <div
        className="flex flex-wrap items-center w-full min-h-[35px] bg-gray-50 border border-gray-300 text-gray-900 text-[12px] rounded-lg placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onClick={handleDivClick}
      >
        <div className="flex w-full flex-wrap gap-2 cursor-text">
          {chips?.map((chip, index) => {
            return (
              <div className="border border-gray-300 flex justify-center items-center rounded-[10px] h-[30px] py-0.5 px-1.5 bg-white">
                {chip}
                <img
                  src="/svgs/Cross.svg"
                  alt="cross"
                  className="w-[18px] ml-2 cursor-pointer"
                  onClick={() => handleChipRemove(index)}
                />
              </div>
            );
          })}
          <input
            id="chips"
            type="text"
            value={inputValue}
            style={{ width: inputWidth }}
            className="appearance-none border-0 h-[30px] bg-gray-50 text-[12px] p-0 focus:border-0 focus:ring-transparent w-[100%]"
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            ref={inputRef}
            onBlur={handleInputBlur}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiChipInput;
