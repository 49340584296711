import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";
import TextInput from "../../../../common/inputElements/TextInput";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../../redux/actions/userActions";

export default function EditRegistrationQuestions({
  open,
  setOpen,
  userData,
  eventId,
  additionalQuestions,
  setMoreDetails,
  transformQuestionKey,
  apiRoute,
  funName,
  editName,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [multiSelectQuestions, setMultiSelectQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let additionalData = {};
    let questions = [];
    if (additionalQuestions) {
      for (const question in additionalQuestions) {
        const answer = additionalQuestions[question];
        if (Array.isArray(answer)) {
          questions.push(question);
          additionalData[question] = answer.map((ans) => ans.label).join(", ");
        } else if (typeof answer !== "object") {
          additionalData[question] = answer;
        }
      }
      setMultiSelectQuestions(questions);
      reset({
        ...additionalData,
      });
    }
  }, [open, additionalQuestions]);

  const onSubmit = async (data) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      alert("please login!! access not found");
      navigate("/login");
    }
    setIsSubmitting(true);
    const formData = { ...data };

    Object.keys(formData).forEach((key) => {
      if (
        multiSelectQuestions.includes(key) &&
        Array.isArray(additionalQuestions[key])
      ) {
        formData[key] = formData[key]
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "")
          .map((item) => ({ label: item }));
      }
    });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/attendee/updateEventSpecificData/${eventId}/${userData._id}`,
        {
          updatedAdditionalData: formData,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        dispatch(getUserDetails({ accessToken: accessToken }));
        dispatch(
          showToast({
            message: "Attendee edited successfully",
            category: "success",
          })
        );
        funName(apiRoute);
        setOpen(false);
        setMoreDetails(false);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "something went wrong!!",
            category: "danger",
          })
        );
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-300 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[21px] pb-[25px] font-[500] text-gray-900 pt-5 flex items-center">
                          <div
                            className="cursor-pointer mr-2"
                            onClick={() => setOpen(false)}
                          >
                            <svg
                              width="23"
                              height="23"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19 12H5"
                                stroke="#585858"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M12 19L5 12L12 5"
                                stroke="#585858"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          {editName === "additionalInfo"
                            ? "Edit Attendee Details"
                            : "Edit Registration Questions"}
                        </Dialog.Title>
                      </div>

                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        id="registrationQuestions"
                      >
                        <div className="relative mt-[50px] flex-1 px-4 sm:px-6 mb-[70px]">
                          <p className="pb-4 font-[500]">Text fields</p>
                          <div className="mt-3 text-[15px] text-wrap">
                            {additionalQuestions &&
                              Object.entries(additionalQuestions)?.map(
                                ([question, answer, index]) =>
                                  transformQuestionKey(question) && (
                                    <div
                                      key={index}
                                      className="mb-3 whitespace-normal	"
                                    >
                                      <div className="font-medium text-black text-[15px]">
                                        {!Array.isArray(answer) &&
                                          typeof answer !== "object" && (
                                            <div className="whitespace-normal break-words">
                                              <TextInput
                                                register={register}
                                                type="text"
                                                id={question}
                                                disabled={
                                                  /(^|[^<])(https?:\/\/[\S]+(\b|$)|www\.[^\s]+(\b|$))(\W|$)/gi.test(
                                                    answer
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                label={transformQuestionKey(
                                                  question
                                                )}
                                                // defaultValue={answer}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>

                          <div className="mt-3 text-[15px]  text-wrap">
                            <p className="pb-4 font-[500]">
                              Multi-Select fields (Seprated By Comma)
                            </p>
                            {additionalQuestions &&
                              Object.entries(additionalQuestions)?.map(
                                ([question, answer]) =>
                                  transformQuestionKey(question) && (
                                    <div
                                      key={question}
                                      className="mb-3 whitespace-normal"
                                    >
                                      <div className="font-medium text-black text-[15px]  ">
                                        {Array.isArray(answer) && (
                                          <TextInput
                                            register={register}
                                            type="text"
                                            id={question}
                                            label={transformQuestionKey(
                                              question
                                            )}
                                            // defaultValue={answer
                                            //   .map((ans) => ans.label)
                                            //   .join(", ")}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )
                              )}
                          </div>

                          <div className="fixed bottom-0 w-[87%] bg-white h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              value={
                                isSubmitting ? "Loading..." : "Save Changes"
                              }
                              name="registrationQuestions"
                              disabled={isSubmitting}
                              type="submit"
                              className="h-[42px] w-full bg-primary rounded-[10px] grid place-items-center text-white text-[15px] font-medium cursor-pointer"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
