import React, { useEffect, useRef, useState } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import QrScanner from "qr-scanner";
import { patchRequest } from "../../../../utils/API/api.ts";
import { showToast } from "../../../../redux/actions/toastActions.js";
import { useReactToPrint } from "react-to-print";

const PrintAndCheckinBadge = ({
  registrations,
  setTriggerScan,
  triggerScan,
  setRegistrations,
  camera,
  setCamera,
}) => {
  const eventsId = useMatch("events/:eventId/*");
  const dispatch = useDispatch();
  const [badgeUrl, setBadgeUrl] = useState("");
  const [cameras, setCameras] = useState([]);
  const [searchParams] = useSearchParams();
  const location = searchParams.get("location");
  let videoElemRef = useRef();
  var qrScanner;

  const checkInAttendee = async (attendee, val, uidParam) => {
    try {
      const res = await patchRequest(
        `attendee/${uidParam}/${eventsId.params.eventId}?val=${val}&location=${location}&userId=${uidParam}`
      );

      const newArray = registrations.map((obj) => {
        if (obj._id !== res.data.userData._id) {
          return obj;
        }
        return res.data.userData;
      });
      setRegistrations(newArray);
      if (res.status === 200 && triggerScan === "checkin") {
        dispatch(
          showToast({
            message: `${res.data.userData.firstName} ${res.data.userData.lastName} Checked In`,
            category: "success",
          })
        );
        res.data.data.eventSpecificData.map((data) => {
          if (data.eventId === eventsId.params.eventId) {
            setBadgeUrl(data.badgeUrl);
          }
        });
      } else {
        dispatch(
          showToast({
            message: "Failed to check in attendee",
            category: "danger",
          })
        );
      }
    } catch (err) {
      console.log(err.response.data, "Something Went Wrong");
      dispatch(
        showToast({
          message: err.response.data.message,
          category: "danger",
        })
      );
    }
  };

  useEffect(() => {
    let timerId;
    qrScanner = new QrScanner(
      videoElemRef.current,
      (result) => {
        const urlObj = new URL(result.data);
        const uidParam = urlObj.searchParams.get("uid");

        if (uidParam) {
          const attendee = registrations?.filter((attendee) => {
            return attendee._id === uidParam;
          });

          if (triggerScan === "checkin") {
            checkInAttendee(attendee, "checkin", uidParam);
            qrScanner.stop();
            timerId = setTimeout(() => {
              qrScanner.start();
            }, 2000);
          }
        } else {
          console.log("nothing");
        }
      },
      {
        onDecodeError: (error) => {
          console.log(error);
        },
        highlightScanRegion: true,
        highlightCodeOutline: true,
        preferredCamera: camera,
        // calculateScanRegion: true,
        maxScansPerSecond: 10,
      }
    );

    qrScanner.start();
    return () => {
      clearTimeout(timerId);
      qrScanner.stop();
    };
  }, []);

  const handleClose = () => {
    qrScanner?.destroy();
    setTriggerScan("");
  };

  const changeCamera = async (id) => {
    setTriggerScan("");
    setCamera(camera === "environment" ? "user" : "environment");
    setTimeout(() => {
      setTriggerScan(triggerScan);
    }, 500);
  };

  useEffect(() => {
    function preventBackgroundScroll(event) {
      event.preventDefault();
      event.stopPropagation();
    }

    document.body.style.overflow = "hidden";
    document.addEventListener("scroll", preventBackgroundScroll, {
      passive: false,
    });

    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("scroll", preventBackgroundScroll);
    };
  }, []);

  useEffect(() => {
    if (badgeUrl) {
      setTimeout(() => {
        PrintImage();
      }, 2700);
    }
  }, [badgeUrl]);

  const PrintImage = useReactToPrint({
    content: () => {
      const imgElement = document.createElement("img");
      imgElement.src = badgeUrl;
      imgElement.style.width = "97%";
      imgElement.style.height = "97%";
      imgElement.style.display = "block";
      const containerElement = document.createElement("div");
      containerElement.style.display = "flex";
      containerElement.style.justifyContent = "center";
      containerElement.style.alignItems = "center";
      containerElement.style.width = "100%";
      containerElement.style.height = "100%";
      containerElement.appendChild(imgElement);
      return containerElement;
    },
    onAfterPrint: () => {
      setBadgeUrl("");
    },
  });

  useEffect(() => {
    const listCameras = async () => {
      try {
        const cameraList = await QrScanner.listCameras();
        setCameras(cameraList);
      } catch (error) {
        console.error("Error listing cameras:", error);
      }
    };

    listCameras();
  }, []);

  return (
    <>
      <div className="w-full flex justify-start items-center border-b-2 outline-offset-8 pb-3 relative mb-[20px]">
        <span className="text-[16px] font-[600]">Print Badge and CheckIn</span>

        <img
          src="/svgs/switch-camera.svg"
          alt="camera"
          className="w-[25px] cursor-pointer ml-3"
          onClick={changeCamera}
          title="Switch Camera"
        />

        <span
          className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px] hover:bg-gray-300"
          onClick={handleClose}
        >
          <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px] font-[500] hover:font-[600]">
            x
          </div>
        </span>
      </div>

      <div className="pt-0 md:pt-0 md:mb-0 mx-auto grid place-items-center  w-full md:max-w-[1440px] md:w-full mb-14 h-[300px]">
        <video
          id="videoElem"
          ref={videoElemRef}
          className={`rounded-[15px] relative top-[10px] md:top-[10px] md:w-full md:h-full w-full border-[4px] border-[#d5d5d5] mx-auto`}
        ></video>
      </div>
    </>
  );
};

export default PrintAndCheckinBadge;
