import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../../../redux/actions/userActions";
import SelectWhatsAppTemplate from "../whatsAppMarketing/SelectWhatsAppTemplate";
import Loader from "../../../../common/loader/Loader";
import { useMatch } from "react-router-dom";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { showToast } from "../../../../redux/actions/toastActions";
import AddWhatsappApi from "./AddWhatsappApi";

const WhatsAppMarketing = () => {
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/*");
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [popup, setPopup] = useState(false);
  const [testTemplate, setTestTemplate] = useState(false);
  const [singleTemplate, setSingleTemplate] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [registrationTemplates, setRegistrationTemplates] = useState([]);
  const [otherTemplates, setOtherTemplates] = useState([]);
  const event = useSelector((state) => state.eventData);
  const userDetails = useSelector((state) => state.userDetails);
  const [isDefault, setIsDefault] = useState(true);
  const { savedUserConfig } = userDetails;

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  useEffect(() => {
    let accessToken = localStorage.getItem("accessToken");
    dispatch(getUserDetails({ accessToken: accessToken }));
  }, [savedUserConfig?._id]);

  useEffect(() => {
    if (event) {
      setEventDetails(event);
      if (event?.whatsAppMarketing?.approvalStatus === "approved") {
        setToggle(true);
      }
    }
  }, [event]);

  const handleToogle = async () => {
    if (toggle) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/event/${
            eventDetails?._id
          }/${"approveWhatsAppMarketing"}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              approvalStatus: "Disapproved",
            }),
          }
        );

        const dataResponse = await response.json();
        setEventDetails(dataResponse.savedEventConfig);
        setEventDetails(dataResponse);
        if (response.status !== 200) {
          dispatch(
            showToast({
              message: "Please try again!! some error occurred",
              category: "danger",
            })
          );
        }
        setToggle(false);
        setPopup(false);
      } catch (error) {
        console.log(error);
      }
    } else if (!toggle) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/event/${
            eventDetails?._id
          }/${"requestWhatsAppMarketing"}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              approvalStatus: "Pending",
            }),
          }
        );

        const dataResponse = await response.json();
        if (response.status !== 200) {
          dispatch(
            showToast({
              message: "Please try again!! some error occurred",
              category: "danger",
            })
          );
        }
        setEventDetails(dataResponse.savedEventConfig);
        setToggle(false);
        setPopup(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const getApprovedWhatsAppTemplates = async () => {
      let access_token =
        "EAAujldbGeg8BALjZAYrnSKD3NoZC0Fd02ZBOvqpjuUTDpunTdZBAxeqWZAUAx6Wa8NVr1dRZBkPtBjQU5c4vc25rI66gYIttXuGEKJZBbZBTsZBI7FvYuosrVlWg4w3otJRZAB6S7EHuJjEjFkVK9HALfR5nB66UgsDIrF3EII3PyfRJQ86kMYsXmdsa6QT22jgcjbaMUzj7dEngZDZD";
      let whatsAppBusinessAccountID = "107024945699453";
      let defaultTokens = true;
      if (
        eventDetails?.whatsAppMarketing?.approvalStatus === "approved" &&
        eventDetails?.whatsAppMarketing?.phoneNumberId &&
        eventDetails?.whatsAppMarketing?.whatsAppBusinessAccountID &&
        eventDetails?.whatsAppMarketing?.authorizationToken
      ) {
        access_token = eventDetails?.whatsAppMarketing?.authorizationToken;
        whatsAppBusinessAccountID =
          eventDetails?.whatsAppMarketing?.whatsAppBusinessAccountID;
        defaultTokens = false;
        setIsDefault(false);
      }
      try {
        const response = await fetch(
          `https://graph.facebook.com/v16.0/${whatsAppBusinessAccountID}/message_templates?limit=10&status=approved&access_token=${access_token}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const dataResponse = await response.json();

        if (defaultTokens) {
          const registrationTemplates = dataResponse.data.filter(
            (template) =>
              template.id === "236844172201926" ||
              template.id === "556645986457627" ||
              template.id === "745283603671502" ||
              template?.id === "234336692950182"
          );
          setRegistrationTemplates(registrationTemplates);

          const otherTemplates = dataResponse.data.filter(
            (template) =>
              template.id !== "236844172201926" &&
              template.id !== "556645986457627" &&
              template.id !== "745283603671502" &&
              template.id !== "5809320129177427" &&
              template.id !== "1385196508940899" &&
              template?.id !== "234336692950182"
          );
          setOtherTemplates(otherTemplates);
        } else {
          setRegistrationTemplates(dataResponse.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getApprovedWhatsAppTemplates();
  }, [eventDetails]);

  // let selectedTemplated = "registration_confirmation_basic";
  // const testing = registrationTemplates?.filter(
  //   (template) => template?.name === selectedTemplated
  // )[0];
  // let body = {
  //   messaging_product: "whatsapp",
  //   to: "9773643100",
  //   type: "template",
  //   template: {
  //     name: testing?.name,
  //     language: {
  //       code: "en_US",
  //     },
  //     components: [],
  //   },
  // };
  // let array = { fullName: "Yo", eventTitle: "tilte" };
  // if (testing?.components) {
  //   testing.components.forEach((component) => {
  //     if (component?.type === "HEADER" && component?.format === "IMAGE") {
  //       body.template.components.push({
  //         type: "header",
  //         parameters: [
  //           {
  //             type: "image",
  //             image: {
  //               link: "badgeUrl",
  //             },
  //           },
  //         ],
  //       });
  //     } else if (component?.type === "BODY") {
  //       let parameterNames = component?.example?.body_text[0];
  //       console.log(parameterNames);
  //       const parameters = parameterNames.map((paramName) => ({
  //         type: "text",
  //         text: array[paramName],
  //       }));
  //       body.template.components.push({
  //         type: "body",
  //         parameters,
  //       });
  //     } else if (component?.type === "BUTTONS") {
  //       body.template.components.push({
  //         type: "button",
  //         sub_type: "url",
  //         index: "0",
  //         parameters: [
  //           {
  //             type: "text",
  //             text: "?tab=people",
  //           },
  //         ],
  //       });
  //     }
  //   });
  // }

  return (
    <>
      {eventDetails.length === 0 ||
      eventDetails.loading ||
      registrationTemplates === undefined ||
      otherTemplates === undefined ? (
        <Loader />
      ) : (
        <div className="w-full md:w-[845px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
          <div className="font-[600] w-[375px] mx-auto md:w-[845px] text-[24px] text-[#585858] flex justify-between items-center mt-2">
            <span className="">
              Whatsapp Marketing
              {eventDetails?.whatsAppMarketing.approvalStatus === "pending" ? (
                <span className="text-[15px] text-primary">
                  (Access Requested)
                </span>
              ) : eventDetails?.whatsAppMarketing.approvalStatus ===
                "approved" ? (
                <span className="text-[15px] text-[#2ECC71]">(Active)</span>
              ) : eventDetails?.whatsAppMarketing.approvalStatus ===
                "Disapproved" ? (
                <span className="text-[15px] text-[#E74C3C]">(Inactive)</span>
              ) : (
                <></>
              )}
              <span className="text-[15px] text-primary"></span>
            </span>

            <div className="flex gap-2 items-center">
              <div
                className="rounded-full bg-gray-200 p-[3px] cursor-pointer"
                onClick={() => setOpen1(true)}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 14 14"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_863_24274"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="14"
                    height="14"
                  >
                    <rect width="14" height="14" fill="white" />
                  </mask>
                  <g mask="url(#mask0_863_24274)">
                    <path
                      d="M6.41699 11.0833V7.58329H2.91699V6.41663H6.41699V2.91663H7.58366V6.41663H11.0837V7.58329H7.58366V11.0833H6.41699Z"
                      fill="white"
                    />
                  </g>
                </svg>
              </div>

              <p className="text-[13px] font-normal	text-[#A55EEA] flex justify-center items-center">
                <label
                  htmlFor="whatsapp"
                  className="inline-flex relative items-center cursor-pointer"
                >
                  <input
                    type="checkbox"
                    value=""
                    id={"whatsapp"}
                    onChange={() => setPopup(true)}
                    checked={toggle}
                    className="sr-only peer"
                  />
                  <span className="w-[57px] h-[30px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[25px] after:w-[25px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
                </label>
                {/* )} */}
              </p>
            </div>
          </div>

          {/* request access popup */}
          {popup && (
            <div className="">
              <div
                className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-40 fixed w-[100%] md:left-0"
                onClick={() => setPopup(false)}
              ></div>
              <div
                className={`mymd:w-[400px] h-[210px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
              >
                <div className="h-[35px] font-semibold text-[20px] border-b-2 outline-offset-4 relative">
                  {toggle ? "Disable Access" : "Request Access"}
                  <span
                    className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px]"
                    onClick={() => setPopup(false)}
                  >
                    <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px]">
                      x
                    </div>
                  </span>
                  <div className="h-[85px] flex justify-evenly items-center mt-2 font-[500]">
                    {toggle
                      ? "You're requesting to disable your WhatsApp marketing access."
                      : eventDetails?.whatsAppMarketing.approvalStatus ===
                        "pending"
                      ? "You have already requested access to WhatsApp marketing."
                      : "You're requesting access to WhatsApp marketing."}
                  </div>

                  {eventDetails?.whatsAppMarketing.approvalStatus ===
                  "pending" ? (
                    <div className="h-[40px]">
                      Status: <span className="text-[#E74C3C]">Pending</span>
                    </div>
                  ) : (
                    <div className="flex justify-between items-center h-[40px] w-[360px] mt-2">
                      <button
                        className="border rounded-lg bg-white text-black text-[14px] w-[46%] h-[40px]"
                        onClick={() => setPopup(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-lg bg-primary text-white text-[14px] w-[46%] h-[40px]"
                        onClick={handleToogle}
                      >
                        Send Request
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="mt-5 text-[20px] text-[#585858] font-[600]">
            {isDefault
              ? "Registration Confirmation Templates"
              : "All Templates"}
          </div>
          <div className="w-[880px] mt-5 rounded-lg scrollbar-hide p-0 flex flex-wrap justify-start gap-[25px]">
            {registrationTemplates?.map((template) => {
              return (
                <div
                  className="border h-[295px] w-[265px] rounded-lg p-3 "
                  style={{
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
                  }}
                  key={template?.id}
                >
                  <div className="h-[100px] flex justify-center items-center flex-col ">
                    <span className="text-[16px] font-[500] break-words text-center">
                      {template?.name
                        .replace(/_|-/g, " ")
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </span>
                    <span className="w-[70px] h-[30px] bg-orange-100 rounded-lg text-orange-500 flex justify-center items-center mt-3">
                      {template?.components[0].type === "HEADER" &&
                      template?.components[0].format === "IMAGE"
                        ? "IMAGE"
                        : "TEXT"}
                    </span>
                  </div>
                  <div className="mt-2 text-[14px] h-[108px] text-[#1C1C1E] font-[500] text-center ">
                    <div className="line-clamp-5">
                      {template?.components[1]?.text ||
                        template?.components[0]?.text}
                    </div>
                  </div>
                  <div className="flex justify-around mt-3 w-[100%] ">
                    {eventDetails?.whatsAppMarketing.resgistrationTemplate
                      .templateName === template.name ? (
                      <button className="bg-[#2ECC71] w-[45%] h-[33px] rounded-[4px] text-white font-[500] text-[13px]">
                        In Use
                      </button>
                    ) : (
                      <button
                        className="bg-primary w-[45%] h-[33px] rounded-[4px] text-white font-[500] text-[13px]"
                        onClick={() => {
                          setOpen(true);
                          setTestTemplate(false);
                          setSingleTemplate(template);
                        }}
                      >
                        Use Me
                      </button>
                    )}

                    <button
                      className="border text-[#1C1C1E] font-[500] w-[45%] rounded-[4px] h-[33px] text-[13px]"
                      onClick={() => {
                        setTestTemplate(true);
                        setOpen(true);
                        setSingleTemplate(template);
                      }}
                    >
                      Test
                    </button>
                  </div>
                </div>
              );
            })}
          </div>

          {isDefault && (
            <>
              <div className="mt-5 text-[20px] text-[#585858] font-[600]">
                Other Templates
                <span className="text-[12px] text-primary">
                  (Coming Soon...)
                </span>
              </div>
              <div className="w-[880px] min-h-[500px] mt-5 rounded-lg scrollbar-hide flex flex-wrap justify-start relative gap-[25px]">
                {otherTemplates?.map((template) => {
                  return (
                    <div
                      className="border h-[295px] w-[265px] rounded-lg p-3"
                      style={{
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
                      }}
                      key={template?.id}
                    >
                      <div className="h-[100px] flex justify-center items-center flex-col ">
                        <span className="text-[16px] font-[500] break-words text-center">
                          {template?.name
                            .replace(/_|-/g, " ")
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </span>

                        <span className="w-[70px] h-[30px] bg-orange-100 rounded-lg text-orange-500 flex justify-center items-center mt-3">
                          {template?.components[0].type === "HEADER" &&
                          template?.components[0].format === "IMAGE"
                            ? "IMAGE"
                            : "TEXT"}
                        </span>
                      </div>
                      <div className="mt-2 text-[14px] h-[108px] text-[#1C1C1E] font-[500] text-center">
                        <div className="line-clamp-5">
                          {template?.components[0].type === "HEADER" ? (
                            <>{template?.components[1].text}</>
                          ) : (
                            <>{template?.components[0].text}</>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-around mt-3 w-[100%] ">
                        <button
                          className="bg-primary w-[45%] h-[33px] rounded-[4px] text-white font-[500] text-[13px] opacity-40"

                          // onClick={() => {
                          //   setOpen(true);
                          //   setTestTemplate(false);
                          //   setSingleTemplate(template);
                          // }}
                        >
                          Use Me
                        </button>
                        <button
                          className="border text-[#1C1C1E] font-[500] w-[45%] rounded-[4px] h-[33px] text-[13px]"
                          onClick={() => {
                            setTestTemplate(true);
                            setOpen(true);
                            setSingleTemplate(template);
                          }}
                        >
                          Test
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}

          <SelectWhatsAppTemplate
            open={open}
            setOpen={setOpen}
            event={eventDetails}
            setEventDetails={setEventDetails}
            savedUserConfig={savedUserConfig}
            testTemplate={testTemplate}
            setTestTemplate={setTestTemplate}
            singleTemplate={singleTemplate}
            setSingleTemplate={setSingleTemplate}
          />

          <AddWhatsappApi
            open={open1}
            setOpen={setOpen1}
            event={eventDetails}
            setEventDetails={setEventDetails}
          />
        </div>
      )}
    </>
  );
};

export default WhatsAppMarketing;
