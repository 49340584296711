import React, { useEffect, useState } from "react";
import { patchRequest } from "../../../../../utils/API/api.ts";
import { useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../../../../redux/actions/toastActions.js";
import { getSingleEvent } from "../../../../../redux/actions/eventActions.js";
import OpenEmailNotifications from "./OpenEmailNotifications.jsx";

const EmailNotifications = () => {
  const eventsId = useMatch("/events/:eventId/*");
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [chips, setChips] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
  }, []);

  useEffect(() => {
    if (event) {
      setEventDetails(event);
    }
  }, [event]);

  useEffect(() => {
    if (eventDetails) {
      if (eventDetails?.emailNotification?.isEmailNotification) {
        if (eventDetails?.emailNotification?.sendTo?.length > 0) {
          setChips(eventDetails?.emailNotification?.sendTo);
        } else {
          let organizerEmail = eventDetails?.primaryOrganizer?.email;
          setChips([organizerEmail]);
        }
        setToggle(true);
      } else {
        let organizerEmail = eventDetails?.primaryOrganizer?.email;
        setChips([organizerEmail]);
        setToggle(false);
      }
    }
  }, [eventDetails]);

  const handleToggle = async (checked) => {
    setIsSubmitting(true);
    let allEmails = [];
    if (checked && chips?.length === 0 && inputValue?.trim() === "") {
      dispatch(
        showToast({
          message: "Please add atleast one email",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (checked && chips.length > 0) {
      const isAllChipsValidEmails = chips.every((chip) =>
        emailPattern.test(chip)
      );
      if (!isAllChipsValidEmails) {
        dispatch(
          showToast({
            message: "Please enter valid emails",
            category: "danger",
          })
        );
        setIsSubmitting(false);
        return;
      } else {
        allEmails = chips;
      }
    } else if (checked && inputValue?.trim() !== "") {
      const isValidEmail = emailPattern.test(inputValue);
      if (!isValidEmail) {
        dispatch(
          showToast({
            message: "Please enter valid email",
            category: "danger",
          })
        );
        setIsSubmitting(false);
        return;
      } else {
        allEmails = [inputValue];
      }
    }

    if (checked && allEmails?.length === 0) {
      dispatch(
        showToast({
          message: "Please add atleast one email",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    let obj = {};
    if (checked) {
      obj = {
        emailNotification: {
          isEmailNotification: checked,
          sendTo: allEmails,
        },
      };
    } else {
      obj = {
        emailNotification: {
          isEmailNotification: checked,
          sendTo: eventDetails?.emailNotification?.sendTo,
        },
      };
    }
    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,
        {
          ...obj,
        }
      );
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent?.data?.savedEventConfig);
        setToggle(
          updatedEvent?.data?.savedEventConfig?.emailNotification
            ?.isEmailNotification
        );
        setChips(
          updatedEvent?.data?.savedEventConfig?.emailNotification?.sendTo
        );

        setOpen(false);
        dispatch(
          showToast({
            message: "Successfully Updated",
            category: "success",
          })
        );
        setIsSubmitting(false);
      }
    } catch (err) {
      setOpen(false);
      setIsSubmitting(false);
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
    }
  };
  return (
    <div className="w-full md:w-[800px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
      <div className="font-[600] mx-auto md:w-[800px] text-[24px] text-[#585858] flex justify-between items-center mt-2">
        <span className="">
          Email Notifications
          <span className="text-[15px] text-primary"></span>
        </span>

        <p className="text-[13px] font-normal	text-[#A55EEA] flex justify-center items-center">
          <label
            htmlFor="whatsapp"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              id={"whatsapp"}
              onChange={(e) => handleToggle(e.target.checked)}
              checked={toggle}
              className="sr-only peer"
            />
            <span className="w-[57px] h-[30px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[25px] after:w-[25px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
          </label>
        </p>
      </div>
      <div className="border w-[800px] min-h-[300px] mt-5 rounded-lg">
        <div
          className="flex border-b h-[70px] items-center px-4 hover:bg-[#f9fbff] cursor-pointer"
          onClick={() => {
            setOpen(true);
            setChips(
              eventDetails?.emailNotification?.sendTo?.length > 0
                ? eventDetails?.emailNotification?.sendTo
                : [eventDetails?.primaryOrganizer?.email]
            );
          }}
        >
          <div>
            <img src="/svgs/clock.svg" alt="clock" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2 " alt="calandar" />
              <span className="text-[15px] line-clamp-1">
                Warpbay EOD Report - {eventDetails?.title}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:&nbsp;
                <div className="flex items-center gap-2 max-w-[500px]">
                  {eventDetails?.emailNotification?.sendTo?.map(
                    (email, index) => {
                      return (
                        <p
                          className="text-primary font-[500] line-clamp-1"
                          key={index}
                        >
                          {email}
                          {eventDetails?.emailNotification?.sendTo?.length -
                            1 ===
                          index
                            ? " - "
                            : ", "}
                        </p>
                      );
                    }
                  )}
                </div>
                <div className="text-green-600 font-medium	">
                  &nbsp;Sent:&nbsp; Everyday at 07:30 pm
                </div>
              </div>
            </p>
          </div>
          {/* <div className="flex">
            <img
              src="/svgs/info.svg"
              alt="info"
              className="cursor-pointer"
              onClick={() => {
                setOpen(true);
              }}
            />
      
          </div> */}
        </div>
      </div>
      <OpenEmailNotifications
        open={open}
        setOpen={setOpen}
        event={eventDetails}
        handleToggle={handleToggle}
        toggle={toggle}
        setChips={setChips}
        chips={chips}
        inputValue={inputValue}
        setInputValue={setInputValue}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default EmailNotifications;
