import { useSearchParams } from "react-router-dom";
import Communications from "./communications/Communications";
import Analytics from "./analytics/Analytics";
import EventHome from "./eventHome/EventHome";
import Registrations from "./registrations/Registrations";
import Speakers from "./speakers/Speakers";
import Sponsors from "./sponsors/Sponsors";
import Settings from "./settings/Settings";
import Schedule from "./schedule/Schedule";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Stalls from "./stalls/Stalls";

function EventLayout() {
  const userDetails = useSelector((state) => state.userDetails);
  const { savedUserConfig } = userDetails;
  const navigate = useNavigate();

  useEffect(() => {
    if (savedUserConfig?.waitlist) {
      navigate("/waitlist");
    }
  }, [savedUserConfig?._id]);

  const [searchParams] = useSearchParams();
  switch (searchParams.get("show")) {
    case "eventHome":
      return <EventHome />;
    case "speakers":
      return <Speakers />;
    case "schedule":
      return <Schedule />;
    case "sponsors":
      return <Sponsors />;
    case "registrations":
      return <Registrations />;
    case "communications":
      return <Communications />;
    case "settings":
      return <Settings />;
    case "analytics":
      return <Analytics />;
    case "stalls":
      return <Stalls />;
    default:
      return <EventHome />;
  }
}

export default EventLayout;
