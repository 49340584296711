import React, { useEffect, useState } from "react";
import axios from "axios";
import AnalyticsCard11 from "./AnalyticsCard11";

const ScannerAnalytics = React.memo(({ eventsId }) => {
  const [totalScans, setTotalScans] = useState([]);
  const [whoMetStats, setWhoMetStats] = useState([]);

  const getStats = () => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/attendee/${eventsId}/getLeadScanStats`
      )
      .then(function (response) {
        setTotalScans(response?.data?.totalLeadScans?.totalScans);
        setWhoMetStats(response?.data?.whoMetStats);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full  md:ml-[0px] md:mt-[25px]">
      <div className="mt-[20px]">
        <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
          <div className="p-5">
            <h2 className="text-lg font-semibold text-slate-800 dark:text-slate-100 mb-2">
              Total Scans
            </h2>

            <div className="flex items-start">
              <div className="text-3xl font-bold text-slate-800 dark:text-slate-100 mr-2">
                {totalScans}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[20px]">
          <AnalyticsCard11 whoMetStats={whoMetStats} />
        </div>
      </div>
    </div>
  );
});

export default ScannerAnalytics;
